import { ResponseModel } from "./../../../shared/common/commonModel/common.model";
import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { catchError } from "rxjs";
import { ForgotPasswordModel, ResetPasswordModel } from "../login.model";
import { LoginService } from "../login.service";
import { CommonService } from "src/app/shared/common/commonService/common.service";
import { ConfirmPasswordValidator } from "../new-password/validation";
import { SecurityService } from "src/app/shared/services/security.service";

@Component({
  selector: "app-new-password",
  templateUrl: "./new-password.component.html",
  styleUrls: ["./new-password.component.scss"],
})
export class NewPasswordComponent extends CommonService implements OnInit {
  public show: boolean = true;
  public Show: boolean = true;
  public newPasswordForm: FormGroup;
  public errorMessage: any;
  public resetPasswordModel = new ResetPasswordModel();
  public validate = false;
  submitted: boolean = false;
  val: string;

  constructor(
    // public authService: AuthService,
    public router: Router,
    private activateRouter: ActivatedRoute,
    private fb: FormBuilder,
    private loginService: LoginService,
    private toaster: ToastrService,
    public renderer: Renderer2,
    public securityService: SecurityService
  ) {
    super(renderer);
    this.newPasswordForm = this.fb.group(
      {
        newPassword: ["", [Validators.required]],
        confirmpassword: ["", Validators.required],
      },
      {
        validator: ConfirmPasswordValidator("newPassword", "confirmpassword"),
      }
    );
  }

  ngOnInit() {}

  showPassword() {
    this.show = !this.show;
  }

  showPassword1() {
    this.Show = !this.Show;
  }
  resetPassword() {
    this.validate = !this.validate;
    if (this.newPasswordForm.valid) {
      this.resetPasswordModel.token = this.loginService.userToken;
      this.resetPasswordModel.email = this.loginService.userEmail;
      var newResetPasswordModel = {
        email: this.resetPasswordModel.email,
        password: this.resetPasswordModel.password,
        token: this.resetPasswordModel.token,
      };
      this.loginService
        .ResetPassword(newResetPasswordModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res: ResponseModel) => {
          if (res.isSuccess) {
            this.toaster.success(res.message, null, { timeOut: 700 });
          } else {
            this.toaster.error(res.message, null, { timeOut: 700 });
          }
        });
      this.router.navigate(["/login"]);
    } else {
      this.toaster.error(
        "New Password and Confirm Password Doesn't Match",
        null,
        { timeOut: 700 }
      );
      Object.keys(this.newPasswordForm.controls).forEach((field) => {
        const control = this.newPasswordForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  handleError(err: any): any {
    throw new Error("Method not implemented.");
  }

  openLoginForm() {
    this.router.navigate(["/login"]);
  }
}
