import { Observable, Subject, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { DataService } from "./../../shared/services/data.service";
import { ResponseModel } from "src/app/shared/common/commonModel/common.model";
import { ActiveDeactiveUserModel } from "./create-user.model";
@Injectable({
  providedIn: "root",
})
export class CreateUserService {
  constructor(private service: DataService) {}

  //POST CREATE USER
  createUser(data: any): Observable<ResponseModel> {
    // let url = "/api/identity/admin/account/register";
    let url = "/api/identity/admin/account/register/register-account";
    const myJSON = JSON.stringify(data);
    console.log(myJSON);
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  //UPDATE EDIT USER
  editUser(data: any): Observable<ResponseModel> {
    let url = "/api/identity/admin/profile";
    const myJSON = JSON.stringify(data);
    console.log(myJSON);
    return this.service.putWithId(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  //acive-deactive USER
  activeDeactiveUser(data: ActiveDeactiveUserModel): Observable<ResponseModel> {
    let url = "/api/identity/admin/profile/change-activeness";
    return this.service.putWithId(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}
