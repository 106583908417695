export class Overview {
}
export type Root = EventOverview[]

export class EventOverview {
  eventName       : string = "NA";
  venueName       : string = "NA";
  eventCoverPhoto = new EventCoverPhoto();
  eventAddress1   : string = "NA";
  eventAddress2   : string = "NA";
  city            : string = "NA";
  state           : string = "NA";
  zipCode         : string = "NA";
  date            : string = "";
  eventStartDateTime       : string = "NA";
  eventEndDateTime         : string = "NA";
  description              : string = "NA";
  eventType       : string = "NA";
  eventTypeId     ?: number;
  eventMode       ?: string = "NA";
  swimmingDistance: number = 0;
  runningDistance : number = 0;
  cyclingDistance  : number = 0;
  fullAddress?    : string = "NA";
  totalRegisteredParticipants ?   : number = 0;
}

export class EventCoverPhoto {
  id              : number;
  url             : string = "NA";
}