<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 px-0">
      <div class="card mb-0">
        <div *ngIf="hideTotalAndSearch" class="d-flex p-3">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 px-0 my-auto">
            <span class="color-orange f-24 py-1"
              >Total Records: {{ ngxDatatableSSRConfig.totalRecords }}</span
            >
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 px-0 my-auto">
            <div class="col-xl-8 d-flex pull-right">
              <span class="align-self-center me-3">Search:</span>
              <input
                type="text"
                class="form-control border-faint py-2"
                (keydown.enter)="sortTableData()"
                [(ngModel)]="ngxDatatableSSRConfig.searchText"
              />
              <svg
                (click)="clearSearchText()"
                *ngIf="ngxDatatableSSRConfig?.searchText.length > 0"
                class="search-cross-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 22.64 22.64"
              >
                <path
                  d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z"
                  transform="translate(0 -0.001)"
                  fill="#000"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="card-body pb-1 pt-0 position-relative overView-height"
          [ngClass]="{
            'px-3': hideTotalAndSearch == true,
            'overView-height': !hideTotalAndSearch,
            'px-0': hideTotalAndSearch == false
          }"
        >
          <h5
            class="position-absolute top-50 start-50 translate-middle color-orange"
            *ngIf="rows.length == 0 || null || undefined"
          >
            {{ emptyMessage }}
          </h5>
          <ngx-datatable
            class="bootstrap"
            [ngClass]="{ overView: !hideTotalAndSearch }"
            [rows]="ngxDatatableSSRConfig?.rows"
            [loadingIndicator]="loadingIndicator"
            [columnMode]="'force'"
            [headerHeight]="55"
            [limit]="ngxDatatableSSRConfig.pageSize"
            [footerHeight]="45"
            [rowHeight]="50"
            [offset]="ngxDatatableSSRConfig.tableOffset"
            [reorderable]="reorderable"
            [scrollbarH]="true"
            [scrollbarV]="false"
            [selectionType]="'checkbox'"
          >
            <!-- (select)="hideTotalAndSearch ? getSingleuserValue($event):null" -->
            <ngx-datatable-column
              headerClass="px-2"
              cellClass="px-2 p-t-5"
              [width]="50"
              prop="isActive"
              name="Active"
              [sortable]="hideTotalAndSearch"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                l
                et-sortDir="sortDir"
                let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn"
              >
                <div class="d-flex">
                  <!-- <span>Active</span> -->
                  <input
                    class="checkboxx"
                    type="checkbox"
                    [checked]="allRowsSelected"
                    (change)="selectFn(!allRowsSelected)"
                  />
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
              >
                <input
                  class="checkboxx"
                  type="checkbox"
                  [checked]="isSelected"
                  (change)="onCheckboxChangeFn($event)"
                />
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              headerClass="px-0"
              cellClass="px-0"
              [width]="160"
              prop="user"
              name="User"
              [sortable]="hideTotalAndSearch"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <div class="d-flex" (click)="sort()">
                  <span>User</span>
                  <span *ngIf="hideTotalAndSearch">
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == 'asc'"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34456"
                          data-name="Path 34456"
                          d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                          transform="translate(-4.667 5)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == 'desc'"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34457"
                          data-name="Path 34457"
                          d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                          transform="translate(0 -4)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == undefined"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34456"
                          data-name="Path 34456"
                          d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                          transform="translate(-4.667 0)"
                          fill="#fff"
                        />
                        <path
                          id="Path_34457"
                          data-name="Path 34457"
                          d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                          transform="translate(-4.667 0)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <div
                  (click)="hideTotalAndSearch ? getSingleuserValue(row) : null"
                >
                  {{ value }}
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [width]="170"
              prop="participantNo"
              name="Participant No"
              [sortable]="hideTotalAndSearch"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <div class="d-flex" (click)="sort()">
                  <span
                    >Participant <br *ngIf="break" />
                    Number</span
                  >
                  <span *ngIf="hideTotalAndSearch">
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == 'asc'"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34456"
                          data-name="Path 34456"
                          d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                          transform="translate(-4.667 5)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == 'desc'"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34457"
                          data-name="Path 34457"
                          d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                          transform="translate(0 -4)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == undefined"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34456"
                          data-name="Path 34456"
                          d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                          transform="translate(-4.667 0)"
                          fill="#fff"
                        />
                        <path
                          id="Path_34457"
                          data-name="Path 34457"
                          d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                          transform="translate(-4.667 0)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <div
                  (click)="hideTotalAndSearch ? getSingleuserValue(row) : null"
                >
                  {{ value }}
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [width]="250"
              cellClass="p-r-4px"
              headerClass="p-r-4px"
              prop="email"
              name="email"
              [sortable]="hideTotalAndSearch"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <div class="d-flex" (click)="sort()">
                  <span>Email</span>
                  <span *ngIf="hideTotalAndSearch">
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == 'asc'"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34456"
                          data-name="Path 34456"
                          d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                          transform="translate(-4.667 5)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == 'desc'"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34457"
                          data-name="Path 34457"
                          d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                          transform="translate(0 -4)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == undefined"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34456"
                          data-name="Path 34456"
                          d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                          transform="translate(-4.667 0)"
                          fill="#fff"
                        />
                        <path
                          id="Path_34457"
                          data-name="Path 34457"
                          d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                          transform="translate(-4.667 0)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <span
                  class="color-black w-100 d-inline-block text-truncate"
                  id="email"
                  title="{{ value }}"
                  (click)="hideTotalAndSearch ? getSingleuserValue(row) : null"
                >
                  {{ value }}
                </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [width]="180"
              prop="registrationDate"
              name="Registered On"
              headerClass="p-r-4px"
              cellClass="p-r-4px"
              [sortable]="hideTotalAndSearch"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <div class="d-flex" (click)="sort()">
                  <span
                    >Registered <br *ngIf="break" />
                    On</span
                  >
                  <span *ngIf="hideTotalAndSearch">
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == 'asc'"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34456"
                          data-name="Path 34456"
                          d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                          transform="translate(-4.667 5)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == 'desc'"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34457"
                          data-name="Path 34457"
                          d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                          transform="translate(0 -4)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == undefined"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34456"
                          data-name="Path 34456"
                          d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                          transform="translate(-4.667 0)"
                          fill="#fff"
                        />
                        <path
                          id="Path_34457"
                          data-name="Path 34457"
                          d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                          transform="translate(-4.667 0)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <div
                  (click)="hideTotalAndSearch ? getSingleuserValue(row) : null"
                >
                  {{ value | date : "MMMM d, y" }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="70"
              headerClass="px-0 p-t-5 d-flex justify-content-center"
              cellClass="px-0 justify-content-center"
              prop="swimming"
              name="Swimming Distance"
              [sortable]="false"
              [resizeable]="false"
              [draggable]="false"
              *ngIf="eventDetails?.eventTypeId != eventTypeEnum.Marathon && eventDetails?.eventTypeId != eventTypeEnum.Running"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <!-- <span>Active</span> -->
                <svg *ngIf="eventDetails?.eventTypeId != eventTypeEnum.Marathon && eventDetails?.eventTypeId != eventTypeEnum.Running"
                  xmlns="http://www.w3.org/2000/svg"
                  width="34.532"
                  height="16.27"
                  viewBox="0 0 34.532 16.27"
                >
                  <g
                    id="Group_40773"
                    data-name="Group 40773"
                    transform="translate(-0.524 -0.588)"
                  >
                    <path
                      id="Path_555"
                      data-name="Path 555"
                      d="M1,7.87c2.067,1.7,7.045,3.666,10.3-1.359a.525.525,0,0,1,.868-.053c2.31,2.887,7.2,5.666,11.531-.031a.5.5,0,0,1,.835.024c2.922,4.185,7.1,4.243,10,1.419"
                      transform="translate(0 0.704)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_556"
                      data-name="Path 556"
                      d="M1,12.748c2.067,1.7,7.045,3.666,10.3-1.359a.525.525,0,0,1,.868-.053c2.31,2.887,7.2,5.666,11.531-.031a.5.5,0,0,1,.835.024c2.922,4.185,7.1,4.243,10,1.419"
                      transform="translate(0 1.408)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_557"
                      data-name="Path 557"
                      d="M1,2.991c2.067,1.7,7.045,3.666,10.3-1.359a.525.525,0,0,1,.868-.053c2.31,2.887,7.2,5.666,11.531-.032a.5.5,0,0,1,.835.024c2.922,4.185,7.1,4.243,10,1.419"
                      transform="translate(0 0)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>

                <!-- <svg *ngIf="eventDetails?.eventTypeId == eventTypeEnum.Marathon" xmlns="http://www.w3.org/2000/svg" width="34.532" height="16.27" viewBox="0 0 34.532 16.27">
                  <g id="Group_40773" data-name="Group 40773" transform="translate(0.476 0.75)">
                    <path id="Path_555" data-name="Path 555" d="M1,7.87c2.067,1.7,7.045,3.666,10.3-1.359a.525.525,0,0,1,.868-.053c2.31,2.887,7.2,5.666,11.531-.031a.5.5,0,0,1,.835.024c2.922,4.185,7.1,4.243,10,1.419" transform="translate(-1 -0.634)" fill="none" stroke="gray" stroke-width="1.5"/>
                    <path id="Path_556" data-name="Path 556" d="M1,12.748c2.067,1.7,7.045,3.666,10.3-1.359a.525.525,0,0,1,.868-.053c2.31,2.887,7.2,5.666,11.531-.031a.5.5,0,0,1,.835.024c2.922,4.185,7.1,4.243,10,1.419" transform="translate(-1 0.07)" fill="none" stroke="gray" stroke-width="1.5"/>
                    <path id="Path_557" data-name="Path 557" d="M1,2.991c2.067,1.7,7.045,3.666,10.3-1.359a.525.525,0,0,1,.868-.053c2.31,2.887,7.2,5.666,11.531-.032a.5.5,0,0,1,.835.024c2.922,4.185,7.1,4.243,10,1.419" transform="translate(-1 -1.338)" fill="none" stroke="gray" stroke-width="1.5"/>
                  </g>
                </svg> -->
                
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <div [ngClass]="{'color-lighter-grey':eventDetails?.eventTypeId == eventTypeEnum.Marathon}"
                  (click)="hideTotalAndSearch ? getSingleuserValue(row) : null"
                >
                  {{ value | number : "1.0-2" }}
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [width]="70"
              prop="cycling"
              headerClass="px-0 p-t-5 d-flex justify-content-center"
              cellClass="px-0 justify-content-center"
              name="Cycling Distance"
              [sortable]="false"
              [resizeable]="false"
              [draggable]="false"
              *ngIf="eventDetails?.eventTypeId != eventTypeEnum.Marathon && eventDetails?.eventTypeId != eventTypeEnum.Running"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <!-- <span>Active</span> -->
                <svg
                  *ngIf="eventDetails?.eventTypeId != eventTypeEnum.Marathon && eventDetails?.eventTypeId != eventTypeEnum.Running"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32.052"
                  height="19.384"
                  viewBox="0 0 32.052 19.384"
                >
                  <g
                    id="Group_40774"
                    data-name="Group 40774"
                    transform="translate(-0.25 -0.25)"
                  >
                    <path
                      id="Path_558"
                      data-name="Path 558"
                      d="M6.972,6.216A6.334,6.334,0,1,0,10.863,7.28"
                      fill="none"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_559"
                      data-name="Path 559"
                      d="M6.961,12.922,9.842,3.366m0,0H22.709m-12.867,0V1M22.709,3.366l2.881,9.557M22.709,3.366l-.656-2.02A.5.5,0,0,0,21.578,1h-3M9.842,1H8.113M9.842,1h2.593"
                      fill="none"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_560"
                      data-name="Path 560"
                      d="M25.58,6.216A6.334,6.334,0,1,1,21.689,7.28"
                      fill="none"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <!-- <svg *ngIf="eventDetails?.eventTypeId == eventTypeEnum.Marathon"xmlns="http://www.w3.org/2000/svg" width="32.052" height="19.384" viewBox="0 0 32.052 19.384">
                  <g id="Group_40774" data-name="Group 40774" transform="translate(0.75 0.75)">
                    <path id="Path_558" data-name="Path 558" d="M6.972,6.216A6.334,6.334,0,1,0,10.863,7.28" transform="translate(-1 -1)" fill="none" stroke="gray" stroke-width="1.5"/>
                    <path id="Path_559" data-name="Path 559" d="M6.961,12.922,9.842,3.366m0,0H22.709m-12.867,0V1M22.709,3.366l2.881,9.557M22.709,3.366l-.656-2.02A.5.5,0,0,0,21.578,1h-3M9.842,1H8.113M9.842,1h2.593" transform="translate(-1 -1)" fill="none" stroke="gray" stroke-width="1.5"/>
                    <path id="Path_560" data-name="Path 560" d="M25.58,6.216A6.334,6.334,0,1,1,21.689,7.28" transform="translate(-1 -1)" fill="none" stroke="gray" stroke-width="1.5"/>
                  </g>
                </svg> -->
                
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <div [ngClass]="{'color-lighter-grey':eventDetails?.eventTypeId == eventTypeEnum.Marathon}"
                  (click)="hideTotalAndSearch ? getSingleuserValue(row) : null"
                >
                  {{ value | number : "1.0-2" }}
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [width]="70"
              prop="running"
              headerClass="px-0 p-t-5 d-flex justify-content-center"
              cellClass="px-0 justify-content-center"
              name="Running Distance"
              [sortable]="false"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <!-- <span>Active</span> -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.293"
                  height="20.404"
                  viewBox="0 0 24.293 20.404"
                >
                  <path
                    id="Path_561"
                    data-name="Path 561"
                    d="M3.216,5.18c-.621-.108-2.057.292-2.187,1.458C.867,8.1,1.191,9.8,6.942,12.389c.594.243,2.171,2.165,3.564,4.293,2.916,4.455,10.056,3.446,11.5,2.511a4.776,4.776,0,0,0,1.926-2.106M3.216,5.18C5.743.644,8.373.752,9.453,1.211c-.729,1.863.567,3.321,2.025,3.969,1.166.518,2.754-.216,3.4-.648q.259.9.5,1.7M3.216,5.18c.648,1.134,2.029,3.744,3.4,4.293,2.835,1.134,3.09,2.683,5.832,6.156,2.43,3.078,8.169,2.216,11.484,1.458m0,0c.285-.922-.286-1.883-3.06-2.43-1.644-.324-2.795-1.106-4.217-4.7M15.383,6.233l-2.042.729m2.042-.729c.236.772.3,1.309.517,1.944m0,0-1.749.729M15.9,8.177c.2.6.564,1.3.757,1.782m0,0-1.777.81"
                    transform="translate(-0.363 -0.266)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="1.3"
                  />
                </svg>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <div
                  (click)="hideTotalAndSearch ? getSingleuserValue(row) : null"
                >
                  {{ value | number : "1.0-2" }}
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [width]="170"
              headerClass="p-r-4px"
              cellClass="p-r-4px justify-content-center"
              prop="levelType"
              name="Level"
              [sortable]="hideTotalAndSearch"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <div
                  class="d-flex w-100 justify-content-center"
                  (click)="sort()"
                >
                  <span>Level</span>
                  <span *ngIf="hideTotalAndSearch">
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == 'asc'"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34456"
                          data-name="Path 34456"
                          d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                          transform="translate(-4.667 5)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == 'desc'"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34457"
                          data-name="Path 34457"
                          d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                          transform="translate(0 -4)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    <svg
                      class="m-l-10"
                      *ngIf="sortDir == undefined"
                      id="Group_40876"
                      data-name="Group 40876"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 18.667 28"
                    >
                      <g id="Group_40875" data-name="Group 40875">
                        <path
                          id="Path_34456"
                          data-name="Path 34456"
                          d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                          transform="translate(-4.667 0)"
                          fill="#fff"
                        />
                        <path
                          id="Path_34457"
                          data-name="Path 34457"
                          d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                          transform="translate(-4.667 0)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <button
                  class="btn-signIn b-r-10 py-1"
                  [ngClass]="{
                    'btn-skyBlue': value == levelType[1],
                    'btn-orange': value == levelType[2],
                    'btn-lightRed': value == levelType[3]
                  }"
                  type="button"
                  (click)="hideTotalAndSearch ? getSingleuserValue(row) : null"
                >
                  <span class="f-14">{{ value }}</span>
                </button>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                ngx-datatable-footer-template
              >
                <div class="col-xl-6"></div>
                <div
                  class="col-md-12 col-sm-12 col-xl-6 d-flex justify-content-between my-2"
                  [ngClass]="{
                    'pe-0': hideTotalAndSearch == true,
                    'pr-1_7_rem': hideTotalAndSearch == false
                  }"
                >
                  <div class=""></div>
                  <div class="pagination-control">
                    <ngb-pagination
                      #ngbPage
                      [boundaryLinks]="false"
                      [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                      [pageSize]="
                        ngxDatatableSSRConfig.pageSize == 0
                          ? 1
                          : ngxDatatableSSRConfig.pageSize
                      "
                      [page]="
                        ngxDatatableSSRConfig.currentPageNumber == 0
                          ? 1
                          : ngxDatatableSSRConfig.currentPageNumber
                      "
                      [maxSize]="5"
                      (pageChange)="onPageChanged($event)"
                    >
                      <ng-template ngbPaginationPrevious>Previous</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
