import { StorageService } from "./../../shared/services/storage.service";
import { LoginService } from "src/app/components/auth/login.service";
import {
  CreateEventModel,
  EventModeEnum,
  EventTypeEnum,
  UpdateEventModel,
  UploadDocumentRequestModel,
} from "./create-event.model";
import { Router } from "@angular/router";
import { Component, Input, OnInit, Renderer2, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators, NgForm } from "@angular/forms";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { CreateEventService } from "./create-event.service";
import { ImgCropperComponent } from "../img-cropper/img-cropper.component";
import { CommonService } from "src/app/shared/common/commonService/common.service";
import { catchError } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { ResponseModel } from "src/app/shared/common/commonModel/common.model";
import * as moment from "moment";
import { NavService } from "src/app/shared/services/nav.service";

@Component({
  selector: "app-create-event",
  templateUrl: "./create-event.component.html",
  styleUrls: ["./create-event.component.scss"],
})
export class CreateEventComponent extends CommonService implements OnInit {
  @ViewChild("createEventForm") createEventForm!: NgForm;
  public errorMessage: any;
  public createEventModel = new CreateEventModel();
  public updateEventModel = new UpdateEventModel();
  public createEventModels: CreateEventModel[];
  checkModelType: String;
  eventTypelist = EventTypeEnum;
  eventModelist = EventModeEnum;
  imageChangedEvent: any = "";
  showImg: any = "";
  eventDate;
  @Input() fromParentSidebar;
  @Input() editEventData: any;
  public validate = false;
  todayDate;
  minTime:string;

  constructor(
    public router: Router,
    public activeModal: NgbActiveModal,
    private CreateEventService: CreateEventService,
    private modalService: NgbModal,
    private config: NgbModalConfig,
    public renderer: Renderer2,
    public toaster: ToastrService,
    public _sanitizer: DomSanitizer,
    public loginService: LoginService,
    public storageservice: StorageService,
    public navService:NavService
  ) {
    super(renderer);
    this.minTime = moment().format('HH:mm');
  }

  ngOnInit(): void {
    this.eventDate = new Date().toISOString().slice(0, 10);
    this.todayDate = new Date().toISOString().slice(0, 10);
    if (this.editEventData != undefined) {
      this.createEventModel = this.editEventData;
      this.createEventModel.eventCoverPhotoId = this.editEventData?.eventCoverPhoto?.id;
      this.showImg = this.editEventData.eventCoverPhoto.url;
      this.createEventModel.eventModeId = parseInt(this.eventModelist[this.editEventData.eventMode]);
      this.eventDate = this.convertUtcToLocalDate(this.editEventData?.eventStartDateTime);
      this.createEventModel.eventEndDateTime = this.convertUtcToLocalTime(this.editEventData?.eventEndDateTime);
      this.createEventModel.eventStartDateTime = this.convertUtcToLocalTime(this.editEventData?.eventStartDateTime);
    }
  }
  
  convertUtcToLocalTime(utcDateTimeString){
    const utcDateTime = moment.utc(utcDateTimeString);
    const localDateTime = utcDateTime.local();
    const formattedTime = localDateTime.format('HH:mm:ss');
    return formattedTime;
  }


  convertUtcToLocalDate(utcDateTimeString){
    const utcDateTime = moment.utc(utcDateTimeString);
    const localDateTime = utcDateTime.local();
    const formattedDate = localDateTime.format('YYYY-MM-DD');
    return formattedDate;
  }

  InputDisabled(): boolean {
    return this.createEventModel?.eventModeId == 2;
  }

  currentDate;


  // # REGION POST CREATE EVENT START
  public createEventSaveData() {
    this.currentDate = new Date();
    if(!this.createEventModel?.eventCoverPhotoId){
      return this.toaster.error("Cover Image are mandatory.")
    }

    if(this.createEventModel.eventStartDateTime != undefined){
      var a = moment(
        this.eventDate + "T" + this.createEventModel.eventStartDateTime
      ).utc();
      if(a < moment(this.currentDate).utc()){
        return this.toaster.error("Start time must be greater than current time");
      }
    }
    else{
      return this.toaster.error("All fields are mandatory")
    }
    if(this.createEventModel.eventEndDateTime != undefined){
      var b = moment(this.eventDate + "T" + this.createEventModel.eventEndDateTime).utc();
      if(b <= a){
        return this.toaster.error("End time must be greater than start time");
      }
    }
    else{
      return this.toaster.error("All fields are mandatory")
    }


    // this.createEventModel.date  = a.format('yyyy-MM-DD');
    this.validate = !this.validate;

    if(this.createEventModel?.eventTypeId != undefined){
      if(this.createEventModel?.eventTypeId != 2 && this.createEventModel?.eventTypeId != 3){
        if (
          this.createEventModel.swimmingDistance == 0 ||
          this.createEventModel.cyclingDistance == 0 ||
          this.createEventModel.runningDistance == 0
        ) {
          return this.toaster.error("Distance can't be zero");
        }
      }
      else{
        if(this.createEventModel.runningDistance == 0){
          return this.toaster.error("Distance can't be zero");
        }
      }
    }

    if (this.createEventForm.valid) {
      // this.createEventModel.eventTypeId = this.eventTypelist["Triathlon"]; // for passing only Triathlon
      this.createEventModel.eventStartDateTime = a.format("HH:mm:ss") + "Z";
      this.createEventModel.eventEndDateTime =   b.format("HH:mm:ss") + "Z";
      this.createEventModel.swimmingDistance = Number(
        this.createEventModel.swimmingDistance
      );
      this.createEventModel.cyclingDistance = Number(
        this.createEventModel.cyclingDistance
      );
      this.createEventModel.runningDistance = Number(
        this.createEventModel.runningDistance
      );
      this.createEventModel.eventStartDateTime = `${this.eventDate}T${this.createEventModel.eventStartDateTime}`;
      this.createEventModel.eventEndDateTime = `${this.eventDate}T${this.createEventModel.eventEndDateTime}`;
      if(this.InputDisabled()){
        this.createEventModel.venueName="";
        this.createEventModel.city="";
        this.createEventModel.state="";
        this.createEventModel.zipCode="";
        this.createEventModel.eventAddress1="";
        this.createEventModel.eventAddress2="";
      }
      let entireData = this.CreateEventService.createEvent(this.createEventModel);
      entireData.subscribe({
        next: (res) => {
          this.toaster.success("Event Post Succesfully");
          this.loginService.getEventData().subscribe((res: ResponseModel) => {
            if (res.isSuccess) {
              this.storageservice.store(
                "getEventData",
                res?.response == "" ? [] : res?.response
              );
              this.loginService.createEvent.next(true);
              // window.location.reload(); // To reload page after event is created
            }
            else{
              this.toaster.error(res.message)
            }
          });
          this.activeModal.dismiss();
        },
        error: (err) => {
          console.log(err);
        },
      });
    } else {
      this.toaster.error("All fields are mandatory", null, { timeOut: 700 });
    }
  }

  // # REGION POST CREATE EVENT END

  //#region edit event
  editEvent(){
    if(!this.createEventModel?.eventCoverPhotoId){
      return this.toaster.error("Cover Image are mandatory.")
    }
    
    if(this.createEventModel.eventStartDateTime != undefined){
      var a = moment(
        this.eventDate + "T" + this.createEventModel.eventStartDateTime
      ).utc();
      if(a < moment(this.currentDate).utc()){
        return this.toaster.error("Start time must be greater than current time");
      }
    }
    else{
      return this.toaster.error("All fields are mandatory")
    }
    if(this.createEventModel.eventEndDateTime != undefined){
      var b = moment(this.eventDate + "T" + this.createEventModel.eventEndDateTime).utc();
      if(b <= a){
        return this.toaster.error("End time must be greater than start time");
      }
    }
    else{
      return this.toaster.error("All fields are mandatory")
    }

    this.updateEventModel.eventId = this.editEventData.id;
    this.updateEventModel.eventName = this.editEventData.eventName;
    this.updateEventModel.eventTypeId = this.editEventData.eventTypeId;
    this.updateEventModel.swimmingDistance = this.editEventData.swimmingDistance;
    this.updateEventModel.runningDistance = this.editEventData.runningDistance;
    this.updateEventModel.cyclingDistance = this.editEventData.cyclingDistance;
    this.updateEventModel.eventCoverPhotoId = this.createEventModel.eventCoverPhotoId;
    this.updateEventModel.city = this.createEventModel.city;
    this.updateEventModel.description = this.createEventModel.description;
    this.updateEventModel.eventAddress1 = this.createEventModel.eventAddress1;
    this.updateEventModel.eventAddress2 = this.createEventModel.eventAddress2;
    this.updateEventModel.eventEndDateTime = (moment(this.eventDate + "T" + this.createEventModel.eventEndDateTime).utc()).toDate().toISOString();
    this.updateEventModel.eventModeId = parseInt((this.createEventModel.eventModeId).toString());
    this.updateEventModel.eventStartDateTime = (moment(this.eventDate + "T" + this.createEventModel.eventStartDateTime).utc()).toDate().toISOString();
    this.currentDate = new Date();
    if(new Date(this.updateEventModel.eventStartDateTime) < this.currentDate){
      return this.toaster.error("Start time must be greater than current time");
    }
    if(new Date(this.updateEventModel.eventEndDateTime) < new Date(this.updateEventModel.eventStartDateTime)){
      return this.toaster.error("End time must be greater than start time");
    }
    this.updateEventModel.state = this.createEventModel.state;
    this.updateEventModel.venueName = this.createEventModel.venueName;
    this.updateEventModel.zipCode = this.createEventModel.zipCode;
    this.CreateEventService.updateEvent(this.updateEventModel)
    .subscribe({
      next: (res) => {
        this.toaster.success(res.message);
        this.activeModal.close(true);
        // this.loginService.getEventData().subscribe((res: ResponseModel) => {
        //   if (res.isSuccess) {
        //   }
        //   else{
        //     this.toaster.error(res.message)
        //   }
        // });
        if(res.isSuccess){
          let eventDeatils:any = {id:this.updateEventModel.eventId,
            eventName:this.updateEventModel.eventName
          }
          // this.loginService.createEvent.next(true);
          this.loginService.updateEvent.next(eventDeatils);
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  //#endregion

   //#region IMAGE START
  public getPhotoId() {
    if (this.showImg != null) {
      const imageFile = this.getFileToBeUploaded(this.showImg);
      let uploadDocumentRequestModel: UploadDocumentRequestModel = {
        file: imageFile,
      };
      this.CreateEventService.GetPhotoIdbyPost(
        this.CreateEventService.toFormData(uploadDocumentRequestModel)
      ).subscribe({
        next: (res: ResponseModel) => {
          if (res.isSuccess) {
            this.createEventModel.eventCoverPhotoId = res?.response?.id;
          }
        },
        error: (err) => {
          console.log("Method not implemented");
        },
      });
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (event.target.files.length > 0) {
      this.openImageCropperPopUp();
    }
  }

  changeFile(event) {
    event.target.value = null;
  }

  // # REGION IMAGE CROP START
  openImageCropperPopUp() {
    this.config.centered = true;
    this.config.size = "md";
    this.config.modalDialogClass = "image-cropper-new";
    const modalRef = this.modalService.open(ImgCropperComponent);
    modalRef.componentInstance.fromParentImageCropper = this.imageChangedEvent;
    modalRef.componentInstance.fromchildToParent.subscribe((res) => {
      this.showImg = res;
      if (this.showImg) {
        this.getPhotoId();
      }
    });
  }
  // # REGION IMAGE CROP END

  getFileToBeUploaded(croppedImage: any): File {
    const base64 = croppedImage;
    const imageName = "name.png";
    const imageBlob = this.dataURItoBlob(base64);
    return new File([imageBlob], imageName, { type: "image/png" });
  }

  dataURItoBlob(dataURI) {
    var imageData = dataURI.toString();
    var byteCharacters = atob(
      imageData.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
    );
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: undefined,
    });
    return blob;
  }

  // # REGION IMAGE END

  // close popup
  closePopUp() {
    this.config.modalDialogClass = "";
    this.activeModal.dismiss();
  }
}
