import { ImageTransform, ImageCroppedEvent, base64ToFile, Dimensions } from 'ngx-image-cropper';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateEventService } from '../../components/create-event/create-event.service'


@Component({
  selector: 'app-img-cropper',
  templateUrl: './img-cropper.component.html',
  styleUrls: ['./img-cropper.component.scss']
})
export class ImgCropperComponent implements OnInit {
  @Input() fromParentImageCropper:any;
  @Output() fromchildToParent: EventEmitter<any> = new EventEmitter();

  
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  constructor(
              public activeModal             : NgbActiveModal,
              private CreateEventService     : CreateEventService,
              ) 
              {}

  ngOnInit(): void {
  this.imageChangedEvent = this.fromParentImageCropper;
  }

imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64));
}

imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
}

cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);

}

loadImageFailed() {
    console.log('Load failed');
}

// ready to crop image
ReadyCropImage(event){
  this.fromchildToParent.emit(this.croppedImage);
  this.activeModal.dismiss();
}

// close popup
closePopUp(){
  this.activeModal.dismiss();
}
}