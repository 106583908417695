import { LevelType } from './../registration-list.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {RegistrationListModel} from '../../registrationList/registration-list.model'
import { RegistrationListService } from '../registration-list.service';
import { EventTypeEnum } from 'src/app/components/create-event/create-event.model';
@Component({
  selector: 'app-registraion-user',
  templateUrl: './registraion-user.component.html',
  styleUrls: ['./registraion-user.component.scss']
})
export class RegistraionUserComponent implements OnInit {
  @Output() goBack = new EventEmitter();
  registrationListModel = new RegistrationListModel()
  levelType = LevelType;
  eventTypeEnum = EventTypeEnum;
  @Input() eventDetails: any;

  constructor(public registrationListService : RegistrationListService) {}

  ngOnInit(): void {
    this.registrationListService.SingleUser.subscribe((res:RegistrationListModel)=>{
      this.registrationListModel = res;
      console.log(this.registrationListModel);
    })
  }

  backToRegistrationList(value){
    this.registrationListService.isSingleUserCardVisible.next(false);
  }

  ngOnChanges(){
    this.eventDetails
  }
}