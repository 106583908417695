import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InviteParticipantRoutingModule } from './invite-participant-routing.module';
import { InviteParticipantComponent } from './invite-participant.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    InviteParticipantComponent
  ],
  imports: [
    CommonModule,
    InviteParticipantRoutingModule,
    SharedModule
  ]
})
export class InviteParticipantModule { }
