import { DatePipe } from '@angular/common';
import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appDateConvertor]'
})
export class DateConvertorDirective implements OnInit, OnDestroy {
  private subscription: Subscription;
  constructor(
    private datePipe: DatePipe,
    private el: ElementRef,
    private ngControl: NgControl,
  ) { }

  ngOnInit(): void {
    const ctrl = this.ngControl.control;
    this.subscription = ctrl.valueChanges
      .subscribe(v => ctrl.setValue(this.datePipe.transform(v), { emitEvent: false }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('blur', ['$event']) public onBlur(event: Event) {
    // this.onDateSelect()
    // this.el.nativeElement.value = this.datePipe.transform(this.el.nativeElement.value);
    
  }

  @HostListener('focus', ['$event']) public onFocus(event: Event) {
   
  }

  onDateSelect() {
    let date: NgbDate = this.el.nativeElement.value;
    // this.dateToString(date)
  }
}
