<div class="fluidcontainer">
    <div class="row">
        <div class="col-xl-6 p-r-10">
            <div class="card p-3 mb-3">
                <div class="card-body p-0">
                    <span class="f-26 font-Rubik-bold">Event Participant Stats</span> 
                    <div class="chart-bg-color mt-3">
                        <apx-chart 
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [xaxis]="chartOptions.xaxis"
                        [yaxis]="chartOptions.yaxis"
                        [stroke]="chartOptions.stroke"
                        [tooltip]="chartOptions.tooltip"
                        [dataLabels]="chartOptions.dataLabels"
                        [colors]="chartOptions.colors"
                        [legend]="chartOptions.legend"
                      >
                      </apx-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 p-l-10">
            <div class="card p-3 mb-3" style="height: 442px !important;">
                <div class="card-body p-0">
                    <span class="f-26 font-Rubik-bold position-relative">Leaderboard</span>
                    <div class="leaderboard-list">
                        <h5 class="position-absolute top-50 start-50 translate-middle color-orange" style="text-align: -webkit-center;" *ngIf="LeaderBoradlist.length == 0 || undefined || null">{{emptyMessage}}</h5>
                        <ul>
                            <li class="my-3 px-2 border rounded" *ngFor="let row of LeaderBoradlist">
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex my-auto">
                                        <div class="list-content p-1 my-auto">
                                            <img  class="img-fluid ImgOutlook-leader" style="width: 40px; height: 40px;"  [src]="row?.profilePhoto?.url" onerror="this.src='../../../../../../../assets/images/dashboard/default.png'" alt="">
                                        </div>
                                        <div class="details p-1 my-auto">
                                            <span class="m-0 f-15">{{row?.user}}</span><br>
                                            <span class="font-weight-light opacity-50 f-10 mt-2">{{row?.country}}</span>
                                        </div>  
                                    </div>
                                    <div class="p-1 text-end position-leader">
                                        <span class="m-0 text-end f-20 my-auto">#{{row?.rank == null ? 'NA' : row?.rank}}</span>
                                        <span class="font-weight-light opacity-50 f-10 ">Participant {{row?.participantNo}}</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div> 
                </div>
            </div>
        </div>
        <div class="col-12">
            <app-leaderboard-list></app-leaderboard-list>
        </div>
    </div>
</div>