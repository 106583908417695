import { Observable, Subject, tap } from 'rxjs';
import { DataService } from './../../shared/services/data.service';
import { Injectable } from '@angular/core';
import { CreateEventModel, UpdateEventModel } from './create-event.model';
import {ResponseModel} from '../../shared/common/commonModel/common.model';

@Injectable({
  providedIn: 'root'
})
export class CreateEventService {
  constructor(private service : DataService) {}
  
  //Post create event
  createEvent(createEventModel : CreateEventModel): Observable <ResponseModel>{
    let url = '/api/dominative/admin/event';
    const myJSON = JSON.stringify(createEventModel);
    console.log(myJSON);
    return this.service.post(url,createEventModel,true).pipe<ResponseModel>(tap((response: any)=>{
      return response;
    }));
  }
  //Update event
  updateEvent(updateEventModel : UpdateEventModel): Observable <ResponseModel>{
    let url = '/api/dominative/admin/event';
    const myJSON = JSON.stringify(updateEventModel);
    console.log(myJSON);
    return this.service.putWithId(url,updateEventModel,true).pipe<ResponseModel>(tap((response: any)=>{
      return response;
    }));
  }

  //get photo id by path
  GetPhotoIdbyPost(data : any): Observable <ResponseModel>{
    let url = '/api/resource/image/EventCoverImage';
    const myJSON = JSON.stringify(data);
    console.log(myJSON);
    return this.service.upload(url,data,true).pipe<ResponseModel>(tap((response: any)=>{
      return response;
    }));
  }

  // helper
  toFormData(model): FormData{
    const formData = new FormData();
    Object.keys(model).forEach(key => {
      formData.append(key, model[key]);
    });
    
    return formData;
  }

 }
