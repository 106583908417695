<div class="container-fluid p-0">
  <div class="card col-12 mb-0">
    <button class="edit-button background-orange color-white border-0 px-3 py-2" [title]="checkEventTime()?'Ongoing or completed events cannot be updated.':''" (mouseenter)="checkEventTime()" (click)="checkEventTime()?openEventStatusPopup():openEditEventPopUp(eventOverview)">Edit Event</button>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-3 col-sm-12 col-md-3 col-xl-3">
          <img
            [ngClass]="{
              'ImgOutlook-none': eventOverview.eventCoverPhoto.url == ''
            }"
            class="img-widht-height img-fluid ImgOutlook"
            [src]="eventOverview?.eventCoverPhoto?.url"
            alt=""
          />
        </div>
        <div class="col-lg-6 col-sm-12 col-md-6 col-xl-6 mt-2 mt-md-0 mt-lg-0">
          <div class="overview-content">
            <div class="d-flex flex-wrap">
              <h2 class="color-orangered font-rubik-medium">
                {{ eventOverview.eventName }}
              </h2>
              <span class="color-black align-self-center f-11">&nbsp;({{eventOverview?.eventMode}})</span>
            </div>
            <p *ngIf="eventOverview?.eventMode == eventModeEnum[1]" class="color-gray font-rubik-medium">
              {{ eventOverview?.fullAddress }}
            </p>
            <div class="col-12 col-xl-7 col-lg-10 col-md-10">
              <div
                class="location d-flex justify-content-between color-black flex-wrap"
              >
                <p class="font-rubik-medium p-r-5">
                  {{ eventOverview.eventType }}
                </p>
                <p *ngIf="eventOverview?.eventStartDateTime !='NA'" class="font-rubik-medium p-r-5">
                  {{ eventOverview?.eventStartDateTime | date : "MMMM d, y" }}
                </p>
                <p *ngIf="eventOverview?.eventStartDateTime != 'NA'" class="font-rubik-medium">
                  {{
                    eventOverview?.eventStartDateTime + "Z" | date : "hh:mm a"
                  }}
                  -
                  {{ eventOverview?.eventEndDateTime + "Z" | date : "hh:mm a" }}
                </p>
              </div>
            </div>
            <div class="">
              <p
                class="text-justify font-Rubik-light color-gray f-16"
                style="line-height: 1.25"
              >
                {{ eventOverview.description }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-12 col-md-3 col-xl-3">
          <div class="mt-3">
            <div
              class="d-flex align-items-center justify-content-between flex-wrap"
            >
              <div class="font-rubik-medium">
                <span class="color-gray">Total Participants:</span>
              </div>
              <span class="p-r-50 f-34 color-orangered">{{
                eventOverview?.totalRegisteredParticipants
              }}</span>
            </div>
            <div class="d-flex justify-content-around mt-3 p-r-20">
              <div class="">
                <div>
                  <svg
                    *ngIf="eventOverview?.eventTypeId != eventTypeEnum.Marathon && eventOverview?.eventTypeId != eventTypeEnum.Running"
                    xmlns="http://www.w3.org/2000/svg"
                    width="42.3"
                    height="23.9"
                    viewBox="0 0 75.586 35.413"
                  >
                    <g
                      id="Group_40773"
                      data-name="Group 40773"
                      transform="translate(0.951 1.5)"
                    >
                      <path
                        id="Path_555"
                        data-name="Path 555"
                        d="M1,9.844c4.535,3.721,15.46,8.045,22.6-2.982a1.153,1.153,0,0,1,1.9-.116c5.068,6.336,15.8,12.434,25.306-.069a1.108,1.108,0,0,1,1.833.052c6.412,9.183,15.591,9.312,21.941,3.114"
                        transform="translate(-1 6.035)"
                        fill="none"
                        stroke="#1b1b1b"
                        stroke-width="3"
                      />
                      <path
                        id="Path_556"
                        data-name="Path 556"
                        d="M1,14.722c4.535,3.721,15.46,8.045,22.6-2.982a1.153,1.153,0,0,1,1.9-.116c5.068,6.336,15.8,12.434,25.306-.069a1.108,1.108,0,0,1,1.833.052c6.412,9.183,15.591,9.312,21.941,3.114"
                        transform="translate(-1 13.408)"
                        fill="none"
                        stroke="#1b1b1b"
                        stroke-width="3"
                      />
                      <path
                        id="Path_557"
                        data-name="Path 557"
                        d="M1,4.965C5.535,8.686,16.46,13.01,23.6,1.983a1.153,1.153,0,0,1,1.9-.116C30.576,8.2,41.3,14.3,50.813,1.8a1.108,1.108,0,0,1,1.833.052c6.412,9.183,15.591,9.312,21.941,3.114"
                        transform="translate(-1 -1.338)"
                        fill="none"
                        stroke="#1b1b1b"
                        stroke-width="3"
                      />
                    </g>
                  </svg>
                  <!-- <svg *ngIf="eventOverview?.eventTypeId == eventTypeEnum.Marathon" xmlns="http://www.w3.org/2000/svg" width="43.056" height="24.905" viewBox="0 0 43.056 24.905">
                    <g id="Group_40781" data-name="Group 40781" transform="translate(-2.638 2.773)">
                      <g id="Group_40773" data-name="Group 40773" transform="translate(3 -2.273)">
                        <path id="Path_555" data-name="Path 555" d="M1,8.892c2.607,2.744,8.887,5.933,12.994-2.2a.591.591,0,0,1,1.095-.085C18,11.28,24.17,15.778,29.636,6.556a.567.567,0,0,1,1.054.038c3.686,6.773,8.963,6.868,12.613,2.3" transform="translate(-1 2.819)" fill="none" stroke="#8c8c8c" stroke-width="1"/>
                        <path id="Path_556" data-name="Path 556" d="M1,13.77c2.607,2.744,8.887,5.934,12.994-2.2a.591.591,0,0,1,1.095-.085c2.914,4.673,9.081,9.17,14.547-.051a.567.567,0,0,1,1.054.038c3.686,6.773,8.963,6.868,12.613,2.3" transform="translate(-1 6.976)" fill="none" stroke="#8c8c8c" stroke-width="1"/>
                        <path id="Path_557" data-name="Path 557" d="M1,4.013c2.607,2.744,8.887,5.934,12.994-2.2a.591.591,0,0,1,1.095-.085C18,6.4,24.17,10.9,29.636,1.678a.567.567,0,0,1,1.054.038c3.686,6.773,8.963,6.868,12.613,2.3" transform="translate(-1 -1.338)" fill="none" stroke="#8c8c8c" stroke-width="1"/>
                      </g>
                    </g>
                  </svg> -->
                </div>
                <div class="justify-content-center d-flex align-items-center" *ngIf="eventOverview?.eventTypeId != eventTypeEnum.Marathon && eventOverview?.eventTypeId != eventTypeEnum.Running">
                  <span class="f-16 font-rubik-medium" [ngClass]="{'color-lighter-grey':eventOverview?.eventTypeId == eventTypeEnum.Marathon}">{{
                    eventOverview.swimmingDistance | number : "1.0-2"
                  }}</span
                  ><span class="px-1 f-8 font-rubik-medium" [ngClass]="{'color-lighter-grey':eventOverview?.eventTypeId == eventTypeEnum.Marathon}">miles</span>
                </div>
                <div *ngIf="eventOverview?.eventTypeId != eventTypeEnum.Marathon && eventOverview?.eventTypeId != eventTypeEnum.Running" class="text-center f-12 mt-2 font-rubik-medium">
                  <span class="color-gray">SWIM</span>
                </div>
              </div>
              <div class="">
                <div>
                  <svg
                    *ngIf="eventOverview?.eventTypeId != eventTypeEnum.Marathon && eventOverview?.eventTypeId != eventTypeEnum.Running"
                    xmlns="http://www.w3.org/2000/svg"
                    width="42.3"
                    height="23.9"
                    viewBox="0 0 70.046 42.246"
                  >
                    <g
                      id="Group_40774"
                      data-name="Group 40774"
                      transform="translate(1.5 1.5)"
                    >
                      <path
                        id="Path_558"
                        data-name="Path 558"
                        d="M14.105,6.216a13.9,13.9,0,1,0,8.538,2.335"
                        transform="translate(-1 5.231)"
                        fill="none"
                        stroke="#1b1b1b"
                        stroke-width="3"
                      />
                      <path
                        id="Path_559"
                        data-name="Path 559"
                        d="M6.961,27.164,13.283,6.191m0,0H41.521m-28.238,0V1M41.521,6.191l6.322,20.973M41.521,6.191l-1.44-4.433A1.1,1.1,0,0,0,39.038,1H32.46M13.283,1H9.49m3.793,0h5.69"
                        transform="translate(6.121 -1)"
                        fill="none"
                        stroke="#1b1b1b"
                        stroke-width="3"
                      />
                      <path
                        id="Path_560"
                        data-name="Path 560"
                        d="M33.578,6.216a13.9,13.9,0,1,1-8.538,2.335"
                        transform="translate(20.362 5.231)"
                        fill="none"
                        stroke="#1b1b1b"
                        stroke-width="3"
                      />
                    </g>
                  </svg>
                  <!-- <svg *ngIf="eventOverview?.eventTypeId == eventTypeEnum.Marathon" xmlns="http://www.w3.org/2000/svg" width="44.451" height="26.435" viewBox="0 0 44.451 26.435">
                    <g id="Group_40774" data-name="Group 40774" transform="translate(0.5 0.5)">
                      <path id="Path_558" data-name="Path 558" d="M9.493,6.216a9.009,9.009,0,1,0,5.534,1.513" transform="translate(-1 1.202)" fill="none" stroke="#8c8c8c" stroke-width="1"/>
                      <path id="Path_559" data-name="Path 559" d="M6.961,17.956l4.1-13.592m0,0h18.3m-18.3,0V1m18.3,3.364,4.1,13.592m-4.1-13.592-.933-2.873A.711.711,0,0,0,27.749,1H23.486M11.058,1H8.6m2.458,0h3.687" transform="translate(1.517 -1)" fill="none" stroke="#8c8c8c" stroke-width="1"/>
                      <path id="Path_560" data-name="Path 560" d="M28.407,6.216a9.009,9.009,0,1,1-5.534,1.513" transform="translate(6.551 1.202)" fill="none" stroke="#8c8c8c" stroke-width="1"/>
                    </g>
                  </svg> -->
                  
                </div>
                <div class="justify-content-center d-flex align-items-center" *ngIf="eventOverview?.eventTypeId != eventTypeEnum.Marathon && eventOverview?.eventTypeId != eventTypeEnum.Running">
                  <span class="f-16 font-rubik-medium" [ngClass]="{'color-lighter-grey':eventOverview?.eventTypeId == eventTypeEnum.Marathon}">{{
                    eventOverview.cyclingDistance | number : "1.0-2"
                  }}</span
                  ><span class="px-1 f-8 font-rubik-medium" [ngClass]="{'color-lighter-grey':eventOverview?.eventTypeId == eventTypeEnum.Marathon}">miles</span>
                </div>
                <div *ngIf="eventOverview?.eventTypeId != eventTypeEnum.Marathon && eventOverview?.eventTypeId != eventTypeEnum.Running" class="text-center f-12 mt-2 font-rubik-medium">
                  <span class="color-gray">BIKE</span>
                </div>
              </div>
              <div class="">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42.3"
                    height="23.9"
                    viewBox="0 0 53.475 44.772"
                  >
                    <path
                      id="Path_561"
                      data-name="Path 561"
                      d="M5.863,10.174c-1.363-.237-4.515.64-4.8,3.2-.356,3.2.355,6.932,12.976,12.62,1.3.533,4.764,4.751,7.821,9.421,6.4,9.776,22.068,7.563,25.241,5.51,1.454-.941,3.648-2.745,4.228-4.621M5.863,10.174C11.409.22,17.18.457,19.55,1.464c-1.6,4.088,1.244,7.288,4.444,8.71,2.56,1.138,6.043-.474,7.466-1.422q.569,1.984,1.1,3.733m-26.7-2.311c1.422,2.488,4.452,8.215,7.466,9.421,6.221,2.489,6.782,5.888,12.8,13.509,5.332,6.754,17.927,4.862,25.2,3.2m0,0c.625-2.024-.628-4.133-6.716-5.332C41,30.26,38.48,28.543,35.359,20.661m-2.8-8.176-4.481,1.6m4.481-1.6c.518,1.694.657,2.874,1.136,4.266m0,0-3.839,1.6m3.839-1.6c.449,1.307,1.238,2.843,1.661,3.91m0,0-3.9,1.778"
                      transform="translate(0.487 0.533)"
                      fill="none"
                      stroke="#1b1b1b"
                      stroke-width="3"
                    />
                  </svg>
                </div>
                <div
                  class="justify-content-center font-rubik-medium d-flex align-items-center"
                >
                  <span class="f-16 font-rubik-medium">{{
                    eventOverview.runningDistance | number : "1.0-2"
                  }}</span
                  ><span class="px-1 f-8">miles</span>
                </div>
                <div class="text-center f-12 mt-2 font-rubik-medium">
                  <span class="color-gray">RUN</span>
                </div>
              </div>
            </div>
            <div *ngIf="eventOverview?.eventMode == eventModeEnum[1]" class="m-t-35 px-5">
              <div class="d-flex justify-content-end font-rubik-medium">
                <span>Location:</span><br />
              </div>
              <div class="m-t-10 d-flex justify-content-end text-end">
                <span class="f-20 color-light-gray"
                  >[{{ eventOverview?.fullAddress }}]</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card col-12 mt-3 mb-3">
    <div class="card-body py-3 px-4">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6 my-auto">
          <span class="f-26 font-Rubik-bold">Registered Participants</span>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
          <button
            disabled
            (click)="openInviteParticipantpopup()"
            class="btn h-75 mt-2 font-Rubik-bold background-white px-3 py-0 shadow-btn"
            type="button"
          >
            <span class="color-orange font-Rubik-bold"> +&nbsp;New </span>
          </button>
          <a class="color-orangered p-3 cursor-pointer"
            ><u>
              <span class="font-Rubik-bold" (click)="viewAll()">
                View All
              </span></u
            ></a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="card col-12 mb-0">
    <div class="card-body px-0 pb-0 pt-0">
      <app-registration-list [eventDetails]="eventOverview"></app-registration-list>
    </div>
  </div>
</div>
