export class RegistrationList {
}

export enum LevelType
{
    Beginner     = 1,
    Intermediate = 2,
    Advanced     = 3
}

export class RegistrationListModel {
    isActive        : false;
    firstName           : string;
    lastName            : string;
    participantNo       : string;
    email               : string;
    registrationDate    : string;
    swimming            : string;
    cycling              : string;
    running             : string|number|any;
    levelType           : string;
    profilePhoto        : ProfilePhoto;
    rank                ?: string = 'NA';
    country             ?: string = 'NA';
    user             ?: string = 'NA';
}

export class ProfilePhoto{
    id  :number;
    url :string;
}