import { ResponseModel } from './../../../../../shared/common/commonModel/common.model';
import { DataService } from './../../../../../shared/services/data.service';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardListService {

  constructor(public service: DataService) { }

  // get All leaderboard Data by Event
  getAllLeaderBoardData(data): Observable<ResponseModel> {
    let url = '/api/dominative/admin/participant/leader-board';
    return this.service.post(url,data,true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}