import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeaderboardListRoutingModule } from './leaderboard-list-routing.module';
import { LeaderboardListComponent } from './leaderboard-list.component';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    LeaderboardListRoutingModule
  ]
})
export class LeaderboardListModule { }