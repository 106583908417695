import { LeaderboardService } from './../leaderboard.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { CommonService } from 'src/app/shared/common/commonService/common.service';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { catchError } from 'rxjs';
import { ApexChartOptions } from 'src/app/components/EventList/chart/apex-chart'
import { PaginationBaseModel, NgxDatatableSSRConfig, ResponseModel } from 'src/app/shared/common/commonModel/common.model';
import { RegistrationListService } from '../../registration-list.service';
import { EventlistService } from 'src/app/components/EventList/eventlist.service';
import { RegistrationListModel } from '../../registration-list.model';
import { LeaderboardModel} from '.././leaderboard.model'
 

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent extends CommonService implements OnInit {
  apexChart           : ApexChartOptions;
  chartOptions        : any;
  LeaderBoradlist: LeaderboardModel[]=[];
  id:number ;

  pink   : any = [30,38,35,55,40,36,35,45,33,40,80,30];
  orange : any = [25,40,50,45,55,40,70,50,60,35,70,25];
  emptyMessage: string;
  

  constructor(public registrationListService : RegistrationListService,
              public renderer                : Renderer2,
              public navService              : NavService,
              public leaderboardService      : LeaderboardService,
              public eventlistService        : EventlistService,) 
              {super(renderer); }

  ngOnInit(): void {
    this.setChartData();
    this.navService.sendItem.subscribe((res)=>{
      this.id = res.id;
      console.log("man", res.id);
      this.Leaderborad(this.id)
    })
  }

  setChartData() {
    this.chartOptions = this.apexChart = {
      series: [
        {
          name: "Pink",
          data: this.pink,
        },
        {
          name: "Orange",
          data: this.orange,
        },
      ],

      colors: ['#E33592', '#E38635'],
      chart: {
        height: 340,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      xaxis: {
        // type: "datetime",
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      legend: {
        show: false,
      },

      yaxis: {
        labels: {
          show: false,
          formatter: (value) => {
            return value.toFixed();
          },
          // formatter: (val) => `${val}`,
        },
        // tickAmount: 6,
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },

      // legend: {
      //   show: false,
      //   showForSingleSeries: false,
      //   showForNullSeries: false,
      //   showForZeroSeries: false,
      // },
    };
  }

  Leaderborad(leaderId){
    this.leaderboardService.GetLeaderboardData({eventId: leaderId}).subscribe((res)=>{
      console.log(res);
      this.emptyMessage = res?.message;
      this.LeaderBoradlist = [];
      if(res?.response?.participants.length > 0 && res?.response?.participants.length > 4 ){
        for(let i = 0;i <= 4;i++){
          this.LeaderBoradlist.push(res?.response?.participants[i]);
        }
      }
      else{
        this.LeaderBoradlist = res?.response?.participants;
      }
      console.log(this.navService.sendItem);
      
    })
  }
}