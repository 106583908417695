<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 px-0">
      <div class="card mb-0">
        <div class="d-flex p-3 align-items-center">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 px-0"></div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 px-0">
            <div class="col-xl-8 col-sm-12 col-12 d-flex pull-right">
              <span class="align-self-center me-3">Search:</span>
              <input type="text" class="form-control border-faint py-2" (keydown.enter)="sortTableData()"
                [(ngModel)]="ngxDatatableSSRConfig.searchText">
              <svg (click)="clearSearchText()" *ngIf="ngxDatatableSSRConfig?.searchText.length > 0"
                class="search-cross-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                viewBox="0 0 22.64 22.64">
                <path
                  d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z"
                  transform="translate(0 -0.001)" fill="#000" />
              </svg>
            </div>
          </div>
        </div>
        <div class="card-body px-3 pb-1 pt-0 position-relative">
          <h5 class="position-absolute top-50 start-50 translate-middle color-orange" *ngIf="rows.length == 0 || null || undefined">
            {{emptyMessage}}
          </h5>
          <ngx-datatable 
            class="bootstrap"
            canAutoResize="true"
            [rows]="ngxDatatableSSRConfig.rows" 
            [loadingIndicator]="loadingIndicator"
            [columnMode]="'force'" 
            [headerHeight]="55" 
            [limit]="ngxDatatableSSRConfig.pageSize" 
            [footerHeight]="45"
            [rowHeight]="50" 
            [offset]="ngxDatatableSSRConfig.tableOffset" 
            [reorderable]="reorderable"
            [scrollbarH]="true" 
            [scrollbarV]="false">
            <ngx-datatable-column 
              prop="permissions" 
              name="Permissions" 
              [sortable]="false"
              [resizeable]="false" 
              [draggable]="false">
              <ng-template 
                ngx-datatable-header-template>
                <div class="">
                  <span>Permissions</span>
                </div>
              </ng-template>
              <ng-template 
                ngx-datatable-cell-template 
                let-rowIndex="rowIndex" 
                let-row="row" 
                let-value="value">
                {{ row.name }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column 
              *ngFor="let role of permissionMainModel?.roles" 
              [sortable]="false"
              [resizeable]="false" 
              [draggable]="false">
              <ng-template ngx-datatable-header-template>
                <div class="">
                  <span>{{role.name}}</span>
                </div>
              </ng-template>
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value">
                <input class="d-block border-faint" [ngClass]="{'m-l-35': role.name == 'Participant','m-l-40': role.name != 'Participant'}" type="checkbox" [checked]="getIsSelected(row.id, role.id)"
                  (click)="getRoleIsAdmin(role.name) ? false : savePermission(row,role,$event)" />
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template 
                let-rowCount="rowCount" 
                let-pageSize="pageSize" 
                let-selectedCount="selectedCount"
                let-curPage="curPage" 
                let-offset="offset" 
                ngx-datatable-footer-template>
                <div class="col-xl-6"></div>
                <div class="col-md-12 col-sm-12 col-xl-6 d-flex justify-content-between my-2">
                  <div class=""></div>
                  <div class="pagination-control">
                    <ngb-pagination 
                      #ngbPage 
                      [boundaryLinks]="false"
                      [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                      [pageSize]="ngxDatatableSSRConfig.pageSize == 0 ? 1 :ngxDatatableSSRConfig.pageSize"
                      [page]="ngxDatatableSSRConfig.currentPageNumber== 0 ? 1 :ngxDatatableSSRConfig.currentPageNumber"
                      [maxSize]="5" (pageChange)="onPageChanged($event)">
                      <ng-template ngbPaginationPrevious>Previous</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>