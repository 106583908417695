import { PermissionsComponent } from './../../components/Admin/permissions/permissions.component';
import { Routes } from '@angular/router';
import { EventDetailsComponent } from 'src/app/components/EventList/event-details/event-details.component';


export const content: Routes = [
  // {
  //   path: 'sample-page',
  //   loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  // },
  {
    path: 'login',
    loadChildren: () => import('../../components/auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'admin/usermanagement',
    loadChildren: () => import('../../components/Admin/usermanagement/usermanagement.module').then(m => m.UsermanagementModule)
  },
  {
    path: 'admin/usermanagement/createUser',
    loadChildren: () => import('../../components/User_Information/create-user/create-user.module').then(m => m.CreateUserModule)
  },
  {
    path:'admin/permissions',
    component:PermissionsComponent
  },
  {
    path:'eventList/event-details/:id',
    component:EventDetailsComponent
  },
];
