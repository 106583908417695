<div class="container-fluid">
    <div class="row">
        <div class="col-12 px-0">
            <div class="card mb-0">
                <div class="card-body">
                    <div class="cropper">
                        <image-cropper 
                            [imageChangedEvent]="imageChangedEvent" 
                            [maintainAspectRatio]="true"
                            [aspectRatio]="4/4" 
                            [cropperMinWidth]="128"
                            [resizeToWidth]="256"
                            [onlyScaleDown]="false" 
                            [roundCropper]="false"
                            [canvasRotation]="canvasRotation" 
                            [transform]="transform" 
                            [alignImage]="'center'"
                            [style.display]="showCropper ? null : 'none'" 
                            format="png" 
                            (imageCropped)="imageCropped($event)"
                            (imageLoaded)="imageLoaded()" 
                            (cropperReady)="cropperReady($event)"
                            (loadImageFailed)="loadImageFailed()">
                        </image-cropper>  
                    </div> 
                    <div class="form-group row mb-0 mt-3">
                        <div class="col-12 d-flex justify-content-between px-4">
                            <button 
                            class="col-5 btn-signIn background-orange p-1 px-lg-2 border-0" type="button" (click)="ReadyCropImage($event)">
                            <span class="f-16">
                                Save Change
                            </span>
                            </button>
                            <button class="col-5 btn-signIn background-grey p-1 px-lg-2 border-0" type="button" (click)="closePopUp()">
                                <span class="f-16">Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>