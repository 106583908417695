<!-- Page Header Start-->
<div class="page-header background-orangered" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <div class="col-12 d-flex">
      <div class="header-logo-wrapper col-auto d-lg-none px-0">
        <div class="logo-wrapper">
          <a routerLink='/'>
            <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="">
            <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="">
          </a>
        </div>
        <div class="toggle-sidebar border-0 p-0 m-0" (click)="sidebarToggle()">
          <app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
        </div>
      </div>
      <div class="nav-right col-12 pull-right right-header my-auto mx-auto">
        <ul class="nav-menus">
          <button style="margin-right: 20px;" (click)="openCreateEventPopUp()" class="btn btn-block btn-signIn background-white px-2" type="button"><span class="color-orange font-Rubik-bold">Create Event</span></button>
          <!-- <li class="language-nav" (click)="languageToggle()">
            <app-languages></app-languages>
          </li>
          <li>
            <span class="header-search" (click)="searchToggle()">
              <app-feather-icons [icon]="'search'"></app-feather-icons>
            </span>
          </li>
          <li class="onhover-dropdown">
            <app-notification></app-notification>
          </li>
          <li class="onhover-dropdown">
            <app-bookmark></app-bookmark>
          </li>
          <li>
            <div class="mode" (click)="layoutToggle()">
              <i class="fa fa-moon-o" *ngIf="!dark"></i>
              <i class="fa fa-lightbulb-o" *ngIf="dark"></i>
            </div>
          </li>
          <li class="cart-nav onhover-dropdown">
            <app-cart></app-cart>
          </li>
          <li class="onhover-dropdown">
            <app-message-box></app-message-box>
          </li>
          <li class="maximize">
            <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
              <app-feather-icons [icon]="'maximize'"></app-feather-icons>
            </a>
          </li> -->
          <li class="profile-nav onhover-dropdown p-0">
             <app-my-account></app-my-account>
          </li>
        </ul>
      </div>
    </div>
    </div>
    <!-- <app-search></app-search> -->
</div>
<!-- Page Header Ends -->