export class CreateEventModel {
  eventName: string;
  venueName: string;
  eventCoverPhotoId: number;
  eventAddress1: string;
  eventAddress2: string;
  city: string;
  state: string;
  zipCode: string;
  eventStartDateTime: string;
  eventEndDateTime: string;
  description: string;
  eventTypeId: number;
  eventModeId: number;
  swimmingDistance: number =0;
  runningDistance: number=0;
  cyclingDistance: number=0;
}

export class UpdateEventModel {
  eventName: string;
  eventId:number;
  venueName: string;
  eventCoverPhotoId: number;
  eventAddress1: string;
  eventAddress2: string;
  city: string;
  state: string;
  zipCode: string;
  eventStartDateTime: string;
  eventEndDateTime: string;
  description: string;
  eventTypeId: number;
  eventModeId: number;
  swimmingDistance: number =0;
  runningDistance: number=0;
  cyclingDistance: number=0;
}

// export class UploadPictureRequestModel {
//     uploadType: string | null = "UserProfileImage";
//     mainPhoto: File | null;
//     editedPhoto: File | null;
//   }

export class UploadDocumentRequestModel {
  file: File | null;
}

export class photoDataModel {
  fileName: string;
  filePath: string;
  fileType: string;
  id: number;
}

export enum EventTypeEnum {
  "Triathlon" = 1,
  "Marathon" = 2,
  "Running" = 3
  // "5K" = 3,
  // "10k" = 4,
}



export enum EventModeEnum{
  "In-Person" = 1, 
  "Virtual"= 2
}
