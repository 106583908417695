import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[OnlyNumbersLettersAllowed]'
})

export class OnlyNumbersLettersAllowedDirective {

  
@HostBinding('autocomplete') public autocomplete;

  constructor() 
  {
    this.autocomplete = 'off';
  }

@HostListener('keypress' , ['$event)']) public disabledkey(e)
  {
    document.all ? e.keyCode : e.keyCode;
    const charCode = e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (!((charCode == 8) || (charCode == 9) || (charCode == 32) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)))) {
      console.log('charCode Restricted is ' + charCode);
      return false;
    }
    return true;
  }
}
