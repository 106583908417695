import { ResponseModel } from "./../../shared/common/commonModel/common.model";
import { DataService } from "./../../shared/services/data.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import {
  ForgotPasswordModel,
  LoginModel,
  ResetPasswordModel,
  VerifyOtpModel,
} from "./login.model";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  public userEmail: string;
  public userToken: string;
  public createEvent = new BehaviorSubject<any>(false);
  public updateEvent = new BehaviorSubject<any>(undefined);
  constructor(private service: DataService) {}

  // sign in function
  SignIn(data: LoginModel): Observable<ResponseModel> {
    let url = "/api/identity/admin/account/login";
    return this.service.post(url, data, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // Send OTP in function
  SendOTP(data: ForgotPasswordModel): Observable<ResponseModel> {
    // let url = '/api/identity/admin/account/forgot-password';
    let url = "/api/identity/admin/account/forgot-password/send-otp";
    return this.service.post(url, data, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // Send OTP in function
  VerifyOTP(data: VerifyOtpModel): Observable<ResponseModel> {
    let url = "/api/identity/admin/account/forgot-password/verify-otp";
    return this.service.post(url, data, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // reset password in function
  ResetPassword(data: ResetPasswordModel): Observable<ResponseModel> {
    // let url = "/api/identity/admin/account/reset-password";
    let url = "/api/identity/admin/account/forgot-password/reset-password";
    return this.service.post(url, data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  //get event Data with id and name
  getEventData(): Observable<ResponseModel> {
    let url = "/api/dominative/admin/event/get-event-names";
    return this.service.get(url).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}
