import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appNumberWithTwoDecimalPlaces]'
})
export class NumberWithTwoDecimalPlacesDirective {

  @HostBinding('autocomplete') public autocomplete;
 
  constructor(private el: ElementRef,private ngModel: NgModel) {
    this.autocomplete = 'off';
    // console.log('readonly',this.readOnly)
  }
  @HostListener('keypress', ['$event']) public disableKeys(e) {
    const keyCode = e.keyCode || e.which;
    const inputChar = String.fromCharCode(keyCode);
  
    if (keyCode === 8) {
      // Allow backspace
      return true;
    } else if (keyCode >= 48 && keyCode <= 57) {
      // Allow numbers (0-9)
      return true;
    } else if (inputChar === "." && e.target.value.indexOf(".") === -1) {
      // Allow a single decimal point if it doesn't already exist
      return true;
    } else {
      // Prevent any other characters from being entered
      e.preventDefault();
      return false;
    }
  }  

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
          const formattedValue = this.formatWithDecimalPlaces(parsedValue, 2);
          this.el.nativeElement.value = formattedValue;
          this.ngModel.update.emit(formattedValue);
        }
  }
  
  formatWithDecimalPlaces(number: number, decimalPlaces: number): string {
    return number.toFixed(decimalPlaces);
  }

}
