import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { ResponseModel } from 'src/app/shared/common/commonModel/common.model';
import { DataService } from 'src/app/shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationListService {

  SingleUser              = new BehaviorSubject(undefined);
  isSingleUserCardVisible = new Subject();
  hidetabletotalRecord    = new BehaviorSubject(false);

  constructor(public service : DataService) { }

  // get Registratered Participant Data
  getAllRegisteredParticipantData(data): Observable<ResponseModel> {
    let url = '/api/dominative/admin/participant/get';
    return this.service.post(url,data,true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}
