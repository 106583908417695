export class CreateUser {}

export enum UserRole {
  "Super Admin" = 1,
  "Admin Coach" = 2,
  "User-participant" = 3,
}

export enum RegistrationType {
  Virtual = 1,
  InPerson = 2,
}

export class CreateUserModel {
  // Newly Added parameter according to api
  id?: number | string = 0;
  userName?: string;
  profilePhotoId?: number = 0;
  registrationTypeId?: number = 0;
  levelTypeId?: number = 1;
  countryId?: number = null;
  phoneNumber?: string;
  // Newly Added parameter according to api

  firstName: string;
  lastName: string;
  email: string;
  userRoleId: number;
  password: string;
  confirmPassword: string;
  isDeactivateUser?: boolean;
}
export class EditUserModel {
  firstName: string;
  lastName: string;
  email: string;
  userRoleId: number;
  isDeactivateUser?: boolean;
}

export class ActiveDeactiveUserModel {
  userId: string | number;
  isActive: boolean;
}
