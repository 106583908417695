import { LoginService } from "./../login.service";
import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ForgotPasswordModel } from "../login.model";
import { catchError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/shared/common/commonService/common.service";
import { ResponseModel } from "src/app/shared/common/commonModel/common.model";

@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.scss"],
})
export class ForgetPasswordComponent extends CommonService implements OnInit {
  public forgetPasswordForm: FormGroup;
  public errorMessage: any;
  public forgotPasswordModel = new ForgotPasswordModel();
  public validate = false;

  constructor(
    // public authService: AuthService,
    public router: Router,
    private fb: FormBuilder,
    private loginService: LoginService,
    private toaster: ToastrService,
    public renderer: Renderer2
  ) {
    super(renderer);
    this.forgetPasswordForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {}

  sendOtp() {
    this.validate = !this.validate;
    this.loginService.userEmail = this.forgotPasswordModel.email;
    if (this.forgetPasswordForm.valid == true) {
      this.loginService
        .SendOTP(this.forgotPasswordModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res: ResponseModel) => {
          if (res.isSuccess) {
            this.router.navigate(["/enterOtp"]);
            this.toaster.success(res.message, null, { timeOut: 700 });
          }
        });
    } else {
      this.toaster.error("Please enter valid mail", null, { timeOut: 700 });
    }
  }

  openLoginForm() {
    this.router.navigate(["/login"]);
  }

  handleError(err: any): any {
    throw new Error("Method not implemented.");
  }
}
