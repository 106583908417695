export class Common {
}

export class ResponseModel {
    isSuccess    : boolean;
    message      : string;
    response     : any;
    errors       : string[];
    status       : number;
    totalRecords : number=0;
}

export class PaginationBaseModel {
    sortColumn   : string | null = null;
    sortDirection: number = 0;
    pageNumber   : number = 0;
    pageSize     : number = 10;
    searchText   : string = '';
    id?          :number = 0;
    eventId?     : number;
}

export class NgxDatatableSSRConfig {
    rows              : any[] = [];
    totalRecords      : number;
    pageSize          : number = 15;
    getAll            : boolean= false;
    searchText        : string = '';
    currentPageNumber : number = 0;
    tableOffset       : number = 0;
  
    limitOptions = [
      {
        key: '5',
        value: 5
      },
      {
        key: '10',
        value: 10
      },
      {
        key: '15',
        value: 15
      },
      {
        key: '20',
        value: 20
      },
      {
        key: '30',
        value: 30
      },
      {
        key: '50',
        value: 50
      },
      {
        key: 'All',
        value: 1
      },
    ];
}