import { OnlyNumbersDirective } from "./../../../shared/directives/only-numbers.directive";
import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "../login.service";
import { ForgotPasswordModel, VerifyOtpModel } from "../login.model";
import { ToastrService } from "ngx-toastr";
import { catchError } from "rxjs";
import { CommonService } from "src/app/shared/common/commonService/common.service";
import { ResponseModel } from "src/app/shared/common/commonModel/common.model";

@Component({
  selector: "app-enter-otp",
  templateUrl: "./enter-otp.component.html",
  styleUrls: ["./enter-otp.component.scss"],
})
export class EnterOtpComponent extends CommonService implements OnInit {
  public enterOtpForm: FormGroup;
  public errorMessage: any;
  public forgotPasswordModel = new ForgotPasswordModel();
  public validate = false;
  submitted: boolean = false;
  public verifyOtpModel = new VerifyOtpModel();
  constructor(
    // public authService: AuthService,
    public router: Router,
    private fb: FormBuilder,
    private loginService: LoginService,
    private toaster: ToastrService,
    public renderer: Renderer2
  ) {
    super(renderer);
    this.enterOtpForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      otpBox1: ["", [Validators.required, Validators.nullValidator]],
      otpBox2: ["", [Validators.required, Validators.nullValidator]],
      otpBox3: ["", [Validators.required, Validators.nullValidator]],
      otpBox4: ["", [Validators.required, Validators.nullValidator]],
    });
  }

  ngOnInit() {
    this.forgotPasswordModel.email = this.loginService.userEmail;
  }

  move(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute("maxlength");
    if (length == maxlength) {
      if (n != "") {
        n.focus();
      }
    }
    if (e.key === "Backspace") {
      if (p != "") {
        p.focus();
      }
    }
  }

  openNewPasswordForm() {
    this.validate = !this.validate;
    if (this.enterOtpForm.valid) {
      this.verifyOtpModel.email = this.enterOtpForm.value["email"];
      this.verifyOtpModel.otp = `${this.enterOtpForm.value["otpBox1"]}${this.enterOtpForm.value["otpBox2"]}${this.enterOtpForm.value["otpBox3"]}${this.enterOtpForm.value["otpBox4"]}`;
      this.loginService
        .VerifyOTP(this.verifyOtpModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res: ResponseModel) => {
          if (res.isSuccess) {
           this.loginService.userToken = res.response.token;
           this.router.navigate(["/account/reset-password"]);
          }
        });
    } else {
      this.toaster.error("Fill Email and OTP first", null, { timeOut: 700 });
      Object.keys(this.enterOtpForm.controls).forEach((field) => {
        const control = this.enterOtpForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  sendOtp() {
    this.loginService
      .SendOTP(this.forgotPasswordModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        console.log("test resendOtp", res);
      });
  }
  handleError(err: any): any {
    throw new Error("Method not implemented.");
  }

  openLoginForm() {
    this.router.navigate(["/login"]);
  }
}
