<div class="d-flex profile-media">
  <img class="rounded-circle" src="../../../../../../assets/images/other-images/user.png" width="37" height="37" alt="">
  <div class="media-body"><span class="color-white">{{accountDetails.firstName}} {{accountDetails.lastName}}</span>
    <p class="mb-0 color-black">{{accountDetails.userRole}} <i class="middle fa fa-angle-down"></i></p>
  </div> 
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="#"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li><a href="#"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log Out</span></a></li>
</ul>