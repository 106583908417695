<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 px-0">
            <div class="card mb-0 b-r-5">
                <div class="card-body px-3 py-4">
                    <div class="row">
                      <div class="d-flex flex-wrap">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"><h5 class="color-orangered mb-0">{{eventDetails?.eventName}}</h5></div>
                        <div class="d-flex align-self-center col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                            <svg *ngIf="eventDetails?.eventMode == eventModeEnum[1]" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40.813 46.436">
                                <g id="location" transform="translate(-31)">
                                  <g id="Group_231" data-name="Group 231" transform="translate(31)">
                                    <g id="Group_230" data-name="Group 230">
                                      <path id="Path_529" data-name="Path 529" d="M59.158,30.681c4.941-7.754,4.32-6.786,4.463-6.989a14.853,14.853,0,0,0,2.75-8.637A14.965,14.965,0,1,0,39.27,23.8l4.383,6.878C38.967,31.4,31,33.547,31,38.273c0,1.723,1.124,4.178,6.481,6.091a42.939,42.939,0,0,0,13.925,2.072c9.8,0,20.406-2.764,20.406-8.162C71.812,33.546,63.855,31.4,59.158,30.681ZM41.543,22.306q-.022-.035-.047-.069A12.244,12.244,0,1,1,61.415,22.1c-.131.173.553-.889-10.008,15.683Zm9.863,21.409c-10.7,0-17.685-3.146-17.685-5.442,0-1.543,3.589-4.081,11.541-5.07l5,7.842a1.36,1.36,0,0,0,2.294,0l5-7.842c7.952.989,11.541,3.527,11.541,5.07C69.092,40.55,62.17,43.715,51.406,43.715Z" transform="translate(-31)" fill="#e3353b"/>
                                    </g>
                                  </g>
                                  <g id="Group_233" data-name="Group 233" transform="translate(44.604 8.253)">
                                    <g id="Group_232" data-name="Group 232">
                                      <path id="Path_530" data-name="Path 530" d="M187.8,91a6.8,6.8,0,1,0,6.8,6.8A6.81,6.81,0,0,0,187.8,91Zm0,10.883a4.081,4.081,0,1,1,4.081-4.081A4.086,4.086,0,0,1,187.8,101.883Z" transform="translate(-181 -91)" fill="#e3353b"/>
                                    </g>
                                  </g>
                                </g>
                            </svg>
                            <span *ngIf="eventDetails?.eventMode == eventModeEnum[1]" class="m-l-15 font-Rubik-regular color-black">{{eventDetails?.fullAddress}}</span>
                        </div>
                        <div class="d-flex align-self-center mt-1 mt-lg-0 mt-md-2 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                            <svg id="g101" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 45.575 45.575">
                                <defs>
                                  <clipPath id="clip-path">
                                    <path id="path105" d="M0-40H45.575V5.575H0Z" transform="translate(0 40)" fill="#e38635"/>
                                  </clipPath>
                                </defs>
                                <g id="g103" clip-path="url(#clip-path)">
                                  <g id="g109" transform="translate(2.949 31.312)">
                                    <path id="path111" d="M4.854,0V10.925a2.671,2.671,0,0,1-2.67,2.67H-32.154a2.671,2.671,0,0,1-2.67-2.67V7.21" transform="translate(34.824)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g113" transform="translate(2.949 3.718)">
                                    <path id="path115" d="M3.639,4.712H-23.429A2.675,2.675,0,0,1-26.1,2.037V-31.132a2.675,2.675,0,0,1,2.675-2.675H10.9a2.675,2.675,0,0,1,2.675,2.675V-5.223a2.675,2.675,0,0,1-.784,1.891L5.53,3.929A2.675,2.675,0,0,1,3.639,4.712Z" transform="translate(26.104 33.807)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g117" transform="translate(31.595 31.206)">
                                    <path id="path119" d="M0,1.35A2.192,2.192,0,0,0,2.192-.842V-5.3A2.192,2.192,0,0,1,4.383-7.49H8.84a2.192,2.192,0,0,0,2.192-2.192" transform="translate(0 9.682)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g121" transform="translate(2.949 13.259)">
                                    <path id="path123" d="M.966,0H-6.928" transform="translate(6.928)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g125" transform="translate(13.512 13.259)">
                                    <path id="path127" d="M3.562,0H-25.553" transform="translate(25.553)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g129" transform="translate(9.146 0.668)">
                                    <path id="path131" d="M.167.862A1.363,1.363,0,0,1-1.2-.5V-4.82A1.363,1.363,0,0,1,.167-6.182H.473A1.363,1.363,0,0,1,1.836-4.82v1.688" transform="translate(1.196 6.182)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g133" transform="translate(33.398 0.668)">
                                    <path id="path135" d="M.167.862A1.363,1.363,0,0,1-1.2-.5V-4.82A1.363,1.363,0,0,1,.167-6.182H.473A1.363,1.363,0,0,1,1.836-4.82v1.688" transform="translate(1.196 6.182)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g137" transform="translate(7.857 17.265)">
                                    <path id="path139" d="M.467.544H-2.712a.636.636,0,0,1-.636-.636V-3.27a.636.636,0,0,1,.636-.636H.467A.636.636,0,0,1,1.1-3.27V-.091A.636.636,0,0,1,.467.544Z" transform="translate(3.348 3.906)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g137-2" data-name="g137" transform="translate(7.857 25.721)">
                                    <path id="path139-2" data-name="path139" d="M.467.544H-2.712a.636.636,0,0,1-.636-.636V-3.27a.636.636,0,0,1,.636-.636H.467A.636.636,0,0,1,1.1-3.27V-.091A.636.636,0,0,1,.467.544Z" transform="translate(3.348 3.906)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g137-3" data-name="g137" transform="translate(7.857 34.178)">
                                    <path id="path139-3" data-name="path139" d="M.467.544H-2.712a.636.636,0,0,1-.636-.636V-3.27a.636.636,0,0,1,.636-.636H.467A.636.636,0,0,1,1.1-3.27V-.091A.636.636,0,0,1,.467.544Z" transform="translate(3.348 3.906)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g141" transform="translate(19.41 17.265)">
                                    <path id="path143" d="M.467.544H-2.712a.636.636,0,0,1-.636-.636V-3.27a.636.636,0,0,1,.636-.636H.467A.636.636,0,0,1,1.1-3.27V-.091A.636.636,0,0,1,.467.544Z" transform="translate(3.348 3.906)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g141-2" data-name="g141" transform="translate(19.41 25.721)">
                                    <path id="path143-2" data-name="path143" d="M.467.544H-2.712a.636.636,0,0,1-.636-.636V-3.27a.636.636,0,0,1,.636-.636H.467A.636.636,0,0,1,1.1-3.27V-.091A.636.636,0,0,1,.467.544Z" transform="translate(3.348 3.906)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g141-3" data-name="g141" transform="translate(19.41 34.178)">
                                    <path id="path143-3" data-name="path143" d="M.467.544H-2.712a.636.636,0,0,1-.636-.636V-3.27a.636.636,0,0,1,.636-.636H.467A.636.636,0,0,1,1.1-3.27V-.091A.636.636,0,0,1,.467.544Z" transform="translate(3.348 3.906)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g149" transform="translate(30.964 17.265)">
                                    <path id="path151" d="M.467.544H-2.712a.636.636,0,0,1-.636-.636V-3.27a.636.636,0,0,1,.636-.636H.467A.636.636,0,0,1,1.1-3.27V-.091A.636.636,0,0,1,.467.544Z" transform="translate(3.348 3.906)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                  <g id="g149-2" data-name="g149" transform="translate(30.964 25.721)">
                                    <path id="path151-2" data-name="path151" d="M.467.544H-2.712a.636.636,0,0,1-.636-.636V-3.27a.636.636,0,0,1,.636-.636H.467A.636.636,0,0,1,1.1-3.27V-.091A.636.636,0,0,1,.467.544Z" transform="translate(3.348 3.906)" fill="rgba(0,0,0,0)" stroke="#e3353b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                                  </g>
                                </g>
                              </svg>
                            <span *ngIf="eventDetails?.eventStartDateTime != null" class="m-l-15 font-Rubik-regular color-black mx-3">{{eventDetails?.eventStartDateTime | date: 'MMMM d, y'}} &nbsp;&nbsp;&nbsp;&nbsp; Time: {{eventDetails?.eventStartDateTime + 'Z' | date:'hh:mm a'}} - {{eventDetails?.eventEndDateTime + 'Z' | date:'hh:mm a'}}</span>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="card mb-0 mt-3 b-r-5">
                <div class="card-body p-1">
                    <div class="row">
                        <nav ngbNav #nav="ngbNav" [activeId]="tabId" class="nav-tabs nav-pills d-flex justify-content-xl-around justify-content-around mb-0 border-0">
                            <ng-container [ngbNavItem]="1">
                              <a (click)="changeTab(1)" ngbNavLink>Overview</a>
                            </ng-container>
                            <ng-container [ngbNavItem]="2">
                              <a (click)="changeTab(2)" ngbNavLink>Registration List</a>
                            </ng-container>
                            <ng-container [ngbNavItem]="3">
                              <a (click)="changeTab(3)" ngbNavLink>Leaderboard</a>
                            </ng-container>
                          </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mt-3 b-r-5">
    <div *ngIf="tabId == 1" class="">
        <app-overview [eventDetails]="eventDetails" (ViewAllId)="changeId($event)"></app-overview>
    </div>
    <div   *ngIf="tabId == 2 && !isCardShow" class="">
        <app-registration-list *ngIf="tabId == 2" [eventDetails]="eventDetails" (passDatatoUserCard)="userCardData($event)"></app-registration-list>
    </div>

    <div *ngIf="tabId == 3 && !isCardShow" class="">
        <app-leaderboard></app-leaderboard>
    </div>
    
    <div *ngIf="isCardShow" class="">
        <app-registraion-user [eventDetails]="eventDetails" (goBack)="registerListHome($event)"></app-registraion-user>
    </div>
</div>