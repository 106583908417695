<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 px-0">
            <div class="card mb-0">
                <div class="d-flex p-3 align-items-center">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 px-0">
                        <button class="btn btn-block btn-signIn background-orange px-3 py-2"
                            type="button" (click)="openCreateUserPopUp()">+&nbsp;<span class="font-rubik font-Rubik-bold">Create User</span>
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 px-0">
                        <div class="col-xl-8 d-flex pull-right">
                            <span class="align-self-center me-3">Search:</span>
                            <input type="text" class="form-control border-faint py-2" (keydown.enter)="sortTableData()" [(ngModel)]="ngxDatatableSSRConfig.searchText">
                            <svg (click)="clearSearchText()" *ngIf="ngxDatatableSSRConfig?.searchText.length > 0" class="search-cross-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22.64 22.64">
                              <path
                                d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z"
                                transform="translate(0 -0.001)" fill="#000" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="card-body px-3 pb-1 pt-0 position-relative">
                    <h5 class="position-absolute top-50 start-50 translate-middle color-orange" *ngIf="rows.length == 0 || null || undefined">
                      {{emptyMessage}}
                    </h5>
                    <ngx-datatable 
                      class="bootstrap" 
                      [rows]="ngxDatatableSSRConfig.rows" 
                      [loadingIndicator]="loadingIndicator"
                      [columnMode]="'force'" 
                      [headerHeight]="55" 
                      [limit]="ngxDatatableSSRConfig.pageSize"
                      [footerHeight]="45" 
                      [rowHeight]="50" 
                      [offset]="ngxDatatableSSRConfig.tableOffset"
                      [reorderable]="reorderable" 
                      [scrollbarH]="true" 
                      [scrollbarV]="false"
                         >
                        <ngx-datatable-column 
                          [width]="180"
                          prop="firstName"
                          name="User"
                          [sortable]="true"
                          [resizeable]="false"
                          [draggable]="false"
                          >
                          <ng-template 
                            ngx-datatable-header-template 
                            let-sort="sortFn" 
                            let-sortDir="sortDir">
                            <div class="d-flex align-items-center" (click)="sort()">
                              <span>User</span>
                              <svg  class="m-l-10" *ngIf="sortDir =='asc'" id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34456" data-name="Path 34456" d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z" transform="translate(-4.667 5)" fill="#fff"/>
                                </g>  
                              </svg>
                              <svg  class="m-l-10" *ngIf="sortDir =='desc'" id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34457" data-name="Path 34457" d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z" transform="translate(0 -4)" fill="#fff"/>
                                </g>
                              </svg>
                              <svg  class="m-l-10" *ngIf="sortDir == undefined"  id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34456" data-name="Path 34456" d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z" transform="translate(-4.667 0)" fill="#fff"/>
                                  <path id="Path_34457" data-name="Path 34457" d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z" transform="translate(-4.667 0)" fill="#fff"/>
                                </g>
                              </svg>
                            </div>
                          </ng-template>
                          <ng-template 
                            ngx-datatable-cell-template 
                            let-rowIndex="rowIndex" 
                            let-row="row" 
                            let-value="value">
                            <span class="color-black w-100 d-inline-block text-truncate" title="{{ row.firstName }} {{row.lastName}}">
                              {{ row.firstName }} {{row.lastName}}
                            </span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column 
                          [width]="150"
                          prop="userRole"
                          name="User Role"
                          [sortable]="true"
                          [resizeable]="false"
                          [draggable]="false"
                          >
                          <ng-template 
                            ngx-datatable-header-template 
                            let-sort="sortFn" 
                            let-sortDir="sortDir">
                            <div class="d-flex align-items-center" (click)="sort()">
                              <span>User Role</span>
                              <svg  class="m-l-10" *ngIf="sortDir =='asc'" id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34456" data-name="Path 34456" d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z" transform="translate(-4.667 5)" fill="#fff"/>
                                </g>  
                              </svg>
                              <svg  class="m-l-10" *ngIf="sortDir =='desc'" id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34457" data-name="Path 34457" d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z" transform="translate(0 -4)" fill="#fff"/>
                                </g>
                              </svg>
                              <svg  class="m-l-10" *ngIf="sortDir == undefined"  id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34456" data-name="Path 34456" d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z" transform="translate(-4.667 0)" fill="#fff"/>
                                  <path id="Path_34457" data-name="Path 34457" d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z" transform="translate(-4.667 0)" fill="#fff"/>
                                </g>
                              </svg>
                            </div>
                          </ng-template>
                          <ng-template 
                            ngx-datatable-cell-template 
                            let-rowIndex="rowIndex" 
                            let-row="row" 
                            let-value="value">
                            {{ value }}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column 
                          [width]="240"
                          prop="email"
                          name="Email"
                          [sortable]="true"
                          [resizeable]="false"
                          [draggable]="false"
                          >
                          <ng-template 
                            ngx-datatable-header-template 
                            let-sort="sortFn" 
                            let-sortDir="sortDir">
                            <div class="d-flex align-items-center" (click)="sort()">
                              <span>Email</span>
                              <svg  class="m-l-10" *ngIf="sortDir =='asc'" id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34456" data-name="Path 34456" d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z" transform="translate(-4.667 5)" fill="#fff"/>
                                </g>  
                              </svg>
                              <svg  class="m-l-10" *ngIf="sortDir =='desc'" id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34457" data-name="Path 34457" d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z" transform="translate(0 -4)" fill="#fff"/>
                                </g>
                              </svg>
                              <svg  class="m-l-10" *ngIf="sortDir == undefined"  id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34456" data-name="Path 34456" d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z" transform="translate(-4.667 0)" fill="#fff"/>
                                  <path id="Path_34457" data-name="Path 34457" d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z" transform="translate(-4.667 0)" fill="#fff"/>
                                </g>
                              </svg>
                            </div>
                          </ng-template>
                          <ng-template 
                            ngx-datatable-cell-template 
                            let-rowIndex="rowIndex" 
                            let-row="row" 
                            let-value="value">
                            <span class="color-black w-100 d-inline-block text-truncate" title="{{value}}">
                              {{ value }}
                            </span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column 
                          [width]="200"
                          prop="registrationDate"
                          name="Created On"
                          [sortable]="true"
                          [resizeable]="false"
                          [draggable]="false"
                          >
                          <ng-template 
                            ngx-datatable-header-template 
                            let-sort="sortFn" 
                            let-sortDir="sortDir">
                            <div class="d-flex align-items-center" (click)="sort()">
                              <span>Created On</span>
                              <svg  class="m-l-10" *ngIf="sortDir =='asc'" id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34456" data-name="Path 34456" d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z" transform="translate(-4.667 5)" fill="#fff"/>
                                </g>  
                              </svg>
                              <svg  class="m-l-10" *ngIf="sortDir =='desc'" id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34457" data-name="Path 34457" d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z" transform="translate(0 -4)" fill="#fff"/>
                                </g>
                              </svg>
                              <svg  class="m-l-10" *ngIf="sortDir == undefined"  id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34456" data-name="Path 34456" d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z" transform="translate(-4.667 0)" fill="#fff"/>
                                  <path id="Path_34457" data-name="Path 34457" d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z" transform="translate(-4.667 0)" fill="#fff"/>
                                </g>
                              </svg>
                            </div>
                          </ng-template>
                          <ng-template 
                            ngx-datatable-cell-template 
                            let-rowIndex="rowIndex" 
                            let-row="row" 
                            let-value="value">
                            {{ value | date: 'MMM d, y'}}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column 
                          [width]="150"
                          prop="isActive"
                          name="Active"
                          [sortable]="true"
                          [resizeable]="false"
                          [draggable]="false"
                          >
                          <ng-template 
                            ngx-datatable-header-template 
                            let-sort="sortFn" 
                            let-sortDir="sortDir">
                            <div class="d-flex align-items-center" (click)="sort()">
                              <span>Active</span>
                              <svg  class="m-l-10" *ngIf="sortDir =='asc'" id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34456" data-name="Path 34456" d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z" transform="translate(-4.667 5)" fill="#fff"/>
                                </g>  
                              </svg>
                              <svg  class="m-l-10" *ngIf="sortDir =='desc'" id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34457" data-name="Path 34457" d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z" transform="translate(0 -4)" fill="#fff"/>
                                </g>
                              </svg>
                              <svg  class="m-l-10" *ngIf="sortDir == undefined"  id="Group_40876" data-name="Group 40876" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.667 28">
                                <g id="Group_40875" data-name="Group 40875">
                                  <path id="Path_34456" data-name="Path 34456" d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z" transform="translate(-4.667 0)" fill="#fff"/>
                                  <path id="Path_34457" data-name="Path 34457" d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z" transform="translate(-4.667 0)" fill="#fff"/>
                                </g>
                              </svg>
                            </div>
                          </ng-template>
                          <ng-template 
                            ngx-datatable-cell-template 
                            let-rowIndex="rowIndex" 
                            let-row="row" 
                            let-value="value">
                            <input class="d-block m-l-15" type="checkbox" [checked]="value" readonly>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column 
                          [width]="120"
                          prop=""
                          name=""
                          cellClass="p-r-4px"
                          [sortable]="true"
                          [resizeable]="false"
                          [draggable]="false"
                          >
                          <ng-template 
                            ngx-datatable-header-template>
                            <div class="">
                            </div>
                          </ng-template>
                          <ng-template 
                            ngx-datatable-cell-template 
                            let-rowIndex="rowIndex" 
                            let-row="row" 
                            let-value="value">
                            <button class="d-inline-flex align-items-center border-0 bg-transparent" type="button" (click)="openCreateUserPopUpForEdit(row)">
                              <svg class="m-r-15" xmlns="http://www.w3.org/2000/svg" width="16.826" height="16.826" viewBox="0 0 16.826 20">
                                <g id="write" transform="translate(-7.875 0.025)">
                                  <path id="Path_664" data-name="Path 664" d="M9.9,16.816a1.421,1.421,0,0,0,.42-.06l3.6-.72a2.315,2.315,0,0,0,1.06-.58L23.7,6.738a3.586,3.586,0,0,0,0-4.979l-.74-.78a3.611,3.611,0,0,0-5,0L9.239,9.717a2.4,2.4,0,0,0-.58,1.06L7.9,14.416a2.035,2.035,0,0,0,2,2.4Zm.68-5.659L19.3,2.419a1.608,1.608,0,0,1,2.2,0l.76.76a1.551,1.551,0,0,1,0,2.3l-8.7,8.738-3.7.62Z" transform="translate(0)"/>
                                  <path id="Path_665" data-name="Path 665" d="M23.716,90.4H8.96a.9.9,0,0,0-.96.96.959.959,0,0,0,.96.96H23.636a.962.962,0,1,0,.08-1.919Z" transform="translate(-0.1 -72.345)"/>
                                </g>
                              </svg>
                              <span>Edit</span>
                            </button>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-footer>
                          <ng-template
                            let-rowCount="rowCount"
                            let-pageSize="pageSize"
                            let-selectedCount="selectedCount"
                            let-curPage="curPage"
                            let-offset="offset"
                            ngx-datatable-footer-template
                          >
                          <div class="col-xl-6"></div>
                            <div class="col-md-12 col-sm-12 col-xl-6 d-flex justify-content-between my-2">
                              <div class=""></div>
                              <div class="pagination-control">
                                <ngb-pagination #ngbPage [boundaryLinks]="false" [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                                  [pageSize]="ngxDatatableSSRConfig.pageSize == 0 ? 1 :ngxDatatableSSRConfig.pageSize" [page]="ngxDatatableSSRConfig.currentPageNumber== 0 ? 1 :ngxDatatableSSRConfig.currentPageNumber"
                                  [maxSize]="5" (pageChange)="onPageChanged($event)">
                                  <ng-template ngbPaginationPrevious>Previous</ng-template>
	                                <ng-template ngbPaginationNext>Next</ng-template>
                                </ngb-pagination>
                              </div>
                            </div>
                          </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>
