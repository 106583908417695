import { CreateUserComponent } from './../../User_Information/create-user/create-user.component';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { CommonService } from 'src/app/shared/common/commonService/common.service';
import { NgxDatatableSSRConfig, PaginationBaseModel, ResponseModel } from 'src/app/shared/common/commonModel/common.model';
import { UsermanagementService } from './../usermanagement.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UsermanagementMainModel } from '../usermanagement.model';
import { CreateUserModel } from '../../User_Information/create-user.model';

export class companyDB {
  static data = [
      {
          "user": "Pranay",
          "userRole": "User-participant",
          "userRoleId": 30,
          "email": "test@gmail1.com",
          "createdDate": '01-17-2023',
          "isActive":true,
      },
      {
          "user": "Om",
          "userRole": "Super Admin",
          "userRoleId": 20,
          "email": "test@gmail2.com",
          "createdDate": '02-17-2023',
          "isActive":false,
      },
      {
          "user": "Pranay",
          "userRole": "Admin Coach",
          "userRoleId": 10,
          "email": "test@gmail3.com",
          "createdDate": '03-17-2023',
          "isActive":false,
      },
      {
          "user": "Om",
          "userRole": "User-participant",
          "userRoleId": 30,
          "email": "test@gmail4.com",
          "createdDate": '02-17-2023',
          "isActive":true,
      },
      {
          "user": "Lokesh",
          "userRole": "Admin Coach",
          "userRoleId": 10,
          "email": "test@gmail.com",
          "createdDate": '02-12-2023',
          "isActive":true,
      },
      {
          "user": "Akshay",
          "userRole": "testing1",
          "email": "test@gmail.com",
          "createdDate": '10-15-2023',
          "isActive":true,
      },
      {
          "user": "Akshay",
          "userRole": "testing1",
          "email": "test@gmail.com",
          "createdDate": '01-17-2023',
          "isActive":true,
      },
      {
        "user": "Akshay",
        "userRole": "testing1",
        "email": "test@gmail.com",
        "createdDate": '01-17-2023',
        "isActive":true,
    },
    {
      "user": "Akshay",
      "userRole": "testing1",
      "email": "test@gmail.com",
      "createdDate": '01-17-2023',
      "isActive":true,
  },
  {
    "user": "Akshay",
    "userRole": "testing1",
    "email": "test@gmail.com",
    "createdDate": '01-17-2023',
    "isActive":true,
},
{
  "user": "Akshay",
  "userRole": "testing1",
  "email": "test@gmail.com",
  "createdDate": '01-17-2023',
  "isActive":true,
},
{
  "user": "Akshay",
  "userRole": "testing1",
  "email": "test@gmail.com",
  "createdDate": '01-17-2023',
  "isActive":true,
},
{
  "user": "Akshay",
  "userRole": "testing1",
  "email": "test@gmail.com",
  "createdDate": '01-17-2023',
  "isActive":true,
},
{
  "user": "Akshay",
  "userRole": "testing1",
  "email": "test@gmail.com",
  "createdDate": '01-17-2023',
  "isActive":true,
},
{
  "user": "Akshay",
  "userRole": "testing1",
  "email": "test@gmail.com",
  "createdDate": '01-17-2023',
  "isActive":true,
},
{
  "user": "Akshay",
  "userRole": "testing1",
  "email": "test@gmail.com",
  "createdDate": '01-17-2023',
  "isActive":true,
},
{
  "user": "Akshay",
  "userRole": "testing1",
  "email": "test@gmail.com",
  "createdDate": '01-17-2023',
  "isActive":true,
},
{
  "user": "Akshay",
  "userRole": "testing1",
  "email": "test@gmail.com",
  "createdDate": '01-17-2023',
  "isActive":true,
},
{
  "user": "Akshay",
  "userRole": "testing1",
  "email": "test@gmail.com",
  "createdDate": '01-17-2023',
  "isActive":true,
},
  ]
}

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss']
})
export class UsermanagementComponent extends CommonService implements OnInit {
  ColumnMode                 = ColumnMode;
  public company             = [];
  paginationBaseModel        = new PaginationBaseModel();
  usermanagementMainModel    = new UsermanagementMainModel();
  // rows                       : PermissionRecordModel[]=[];
  loadingIndicator           : boolean = true;
  reorderable                : boolean = true;
  checkModelType             : String;
  rows: CreateUserModel[]=[];
  emptyMessage: string;

  constructor(private modalService          : NgbModal,
              public renderer               : Renderer2,
              private config                : NgbModalConfig,
              private usermanagementService : UsermanagementService,
              private toaster               : ToastrService,) 
              { super(renderer);
                // this.config.centered = true;
                // this.config.size = "md";
              }

  ngOnInit(): void {
      this.initDatatable();
      this.loadListData();
  }

  initDatatable(): void {
    this.paginationBaseModel            = new PaginationBaseModel();
    this.ngxDatatableSSRConfig          = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.pageSize = 10;
    this.paginationBaseModel            = {
      sortColumn   : null,
      sortDirection: 0,
      pageNumber   : this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize     : this.ngxDatatableSSRConfig.pageSize,
      searchText   : this.ngxDatatableSSRConfig.searchText
    };
  }

  loadListData(){
    this.usermanagementService.getAllUserManagment(this.paginationBaseModel)
    .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res:ResponseModel) => {
        if (res.isSuccess === true) {
          this.updateTableData(res,res.response);
          this.rows = res.response;
          this.emptyMessage = res?.message;
        }
      });
  }

  onPageChanged(pageNum: number) {
    this.ngxDatatableSSRConfig.rows = [];
    this.onPageChange(pageNum);
    this.paginationBaseModel.pageNumber = this.ngxDatatableSSRConfig.currentPageNumber;
    this.loadListData();
  }

  openCreateUserPopUp(){
    this.checkModelType = 'CreateUser'
    this.config.centered = true;
    this.config.size     = "md";
    const modalRef = this.modalService.open(CreateUserComponent);
    modalRef.componentInstance.fromParent = this.checkModelType;
    modalRef.componentInstance.isReloadAfterSave.subscribe((res:boolean)=>{
      if(res){
        this.loadListData();
      }
    })
  }

  openCreateUserPopUpForEdit(row){
    this.checkModelType = 'EditUser'
    this.config.centered = true;
    this.config.size     = "md";
    const modalRef = this.modalService.open(CreateUserComponent);
    modalRef.componentInstance.editUser = row
    modalRef.componentInstance.fromParent = this.checkModelType;
    modalRef.componentInstance.isReloadAfterSave.subscribe((res:boolean)=>{
      if(res){
        this.loadListData();
      }
    })
  }

  sortTableData(){
    this.paginationBaseModel.searchText = this.ngxDatatableSSRConfig.searchText;
    this.loadListData()
  }

  clearSearchText(){
    this.ngxDatatableSSRConfig.searchText = "";
    this.sortTableData();
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);
    return throwError(error);
  }
}
