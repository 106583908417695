import { NavService } from './../../../shared/services/nav.service';
import { EventlistService } from './../eventlist.service';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationListService } from '../EvenlistTabs/registrationList/registration-list.service';
import { InviteParticipantComponent } from '../invite-participant/invite-participant/invite-participant.component';
import { PaginationBaseModel, NgxDatatableSSRConfig, ResponseModel } from 'src/app/shared/common/commonModel/common.model';
import { CommonService } from 'src/app/shared/common/commonService/common.service';
import { catchError } from 'rxjs';
import { EventOverview } from '../EvenlistTabs/registrationList/overview/overview.model';
import { EventModeEnum } from '../../create-event/create-event.model';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent extends CommonService implements OnInit {

  tabId;
  paginationBaseModel = new PaginationBaseModel();
  checkModelType      : String;
  showHideUseCard     : boolean =false;
  isCardShow          : boolean;
  eventDetails        : EventOverview;
  eventModeEnum = EventModeEnum;

  constructor(private modalService           : NgbModal,
              public renderer                : Renderer2,
              private config                 : NgbModalConfig,
              public registrationListService : RegistrationListService,
              public eventlistService        : EventlistService,
              public navService              : NavService) 
              { 
                super(renderer);
                this.config.centered = true;
                this.config.size = "lg"
              }

  ngOnInit(): void {
    // this.changeTab(1);
    this.registrationListService.isSingleUserCardVisible.subscribe((res:boolean)=>{
      this.isCardShow = res;
    })
    this.eventlistService.updateEventData.subscribe((res:boolean)=>{
      if(res){
        this.getEventdata(this.paginationBaseModel);
      }
    })
    this.initDatatable();
  }

// # REGION PAGINATION START
  initDatatable(): void {
    this.paginationBaseModel = new PaginationBaseModel();
    this.navService.sendItem.subscribe((res:any)=>{
      if(res.id != undefined){
        this.paginationBaseModel.id = res?.id;
        this.eventlistService.eventId = res?.id;
        this.changeTab(1);
      }
      this.getEventdata(this.paginationBaseModel);
    })
  }

// # REGION PAGINATION END

  getEventdata(paginationModel){
    var entireData = this.eventlistService.GetEvents(paginationModel)
    entireData.pipe(catchError((err) => this.handleError(err)))
    .subscribe((res: ResponseModel) => {
      if (res.isSuccess) {
        this.eventDetails = res?.response[0];
      }
    });
  }
  
  handleError(err: any): any {
    throw new Error('Method not implemented.');
  }

  changeTab(value){
    this.tabId = value;
    this.registrationListService.isSingleUserCardVisible.next(false);
    if(value ==1 ){
      this.registrationListService.hidetabletotalRecord.next(false);
    } else if(value == 2){
      this.registrationListService.hidetabletotalRecord.next(true);
    }
  }

  //# REGION OPEN INVITE PARTICIPANT 
  openInviteParticipantpopup(){
    const modalRef = this.modalService.open(InviteParticipantComponent);
    modalRef.componentInstance.fromParent = this.checkModelType;
  }

  userCardData(event:any){
    if(event != null || event != undefined){
      this.registrationListService.isSingleUserCardVisible.next(true);
    }
  }

  registerListHome(value){
    this.tabId = 2;
  }

  changeId(value){
    this.changeTab(2);
  }

}
