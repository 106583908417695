import { ResponseModel } from './../../shared/common/commonModel/common.model';
import { DataService } from './../../shared/services/data.service';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsermanagementService {

  constructor(public service : DataService) { }

// UserManagment Start

  getAllUserManagment( data: any ): Observable <ResponseModel>{
    let url = '/api/identity/admin/profile';
    var myJson = JSON.stringify(data);
    return this.service.post(url,data,true).pipe<ResponseModel>(tap((response: any)=>{
      return response;
    }));
  }

  // UserManagment end

  // Permission start 

  // get getPermissionAccessByUser
  getAllPermission(data): Observable<ResponseModel> {
    let url = '/api/identity/admin/permission/get';
    return this.service.post(url,data,true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

    // update permissionSave
    savePermission(body: any): Observable<ResponseModel> {
      let url = '/api/identity/admin/permission/update';
      return this.service.putWithId(url, body).pipe<ResponseModel>(tap((response: any) => {
        return response;
      }));
    }
    // Permission start 

}
