import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { RegistrationListModel } from '../registration-list.model';
import { DataService } from 'src/app/shared/services/data.service';
import { ResponseModel } from 'src/app/shared/common/commonModel/common.model';
import {LeaderboardModel} from './leaderboard.model'

@Injectable({
  providedIn: 'root'
})



export class LeaderboardService {

  constructor(public service : DataService) { }

  GetLeaderboardData({eventId:Id}): Observable <ResponseModel>{
    let url = '/api/dominative/admin/participant/leader-board';
    const myJSON = JSON.stringify({eventId:Id});
    return this.service.post(url,{eventId:Id},true).pipe<ResponseModel>(tap((response: any)=>{
      return response;
    }));
  }
  
  rows = new Subject<RegistrationListModel[]>();
}