import { OverviewService } from './../overview.service';
import { EventTypeEnum } from './../../../../../create-event/create-event.model';
import { EventOverview } from "./../overview.model";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { InviteParticipantComponent } from "src/app/components/EventList/invite-participant/invite-participant/invite-participant.component";
import { CreateEventComponent } from 'src/app/components/create-event/create-event.component';
import { EventModeEnum } from "src/app/components/create-event/create-event.model";
import { EventlistService } from 'src/app/components/EventList/eventlist.service';
import * as moment from 'moment';
import { EventStatusComponent } from '../event-status/event-status.component';

@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"],
})
export class OverviewComponent implements OnInit {
  eventOverview = new EventOverview();
  checkModelType: String;
  @Input() eventDetails: any;
  @Output() ViewAllId = new EventEmitter();
  eventModeEnum = EventModeEnum;

  eventTypeEnum = EventTypeEnum;

  constructor(private modalService: NgbModal, private config: NgbModalConfig,public eventlistService:EventlistService) {
    this.config.centered = true;
    this.config.size = "lg";
  }

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.eventDetails != undefined) {
      this.eventOverview = this.eventDetails;
    }
  }
  currentDateTime;
  targetDate;
  isLessThanCurrentDate;


  checkEventTime() {
    this.currentDateTime = moment(); // Current date and time
    var eventStartDateTime = moment.utc(this.eventOverview.eventStartDateTime).local(); // Convert event start date-time to local time
    var currentDateTime = moment(this.currentDateTime); // Convert current date-time to moment object
    const isSameDay = eventStartDateTime.isSame(this.currentDateTime, 'day');
    // Compare both date and time
    this.isLessThanCurrentDate = eventStartDateTime.isSameOrBefore(currentDateTime) || isSameDay;
    return this.isLessThanCurrentDate;
}




  openEditEventPopUp(eventOverview){
    this.checkModelType  = 'CreateEvent'
    this.config.centered = true;
    this.config.size     = "lg";
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    const modalRef = this.modalService.open(CreateEventComponent);
    modalRef.componentInstance.editEventData = JSON.parse(JSON.stringify(eventOverview));
    modalRef.result.then(
      (result) => {
          if (result) {
            this.eventlistService.updateEventData.next(result);
          }
      },
      (reason) => {}
  );
  }

  openEventStatusPopup() {
    this.checkModelType = 'EventStatus'
    this.config.centered = true;
    this.config.size = "md";
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    const modalRef = this.modalService.open(EventStatusComponent);

      this.currentDateTime = moment();
      var eventStartDateTime = moment.utc(this.eventOverview.eventStartDateTime).local();
      var eventEndDateTime = moment.utc(this.eventOverview.eventEndDateTime).local();
      var currentDateTime = moment(this.currentDateTime);
      const isSameDay = eventStartDateTime.isSame(this.currentDateTime, 'day');
      const isBetweenEventTimes = this.currentDateTime.isBetween(eventStartDateTime, eventEndDateTime);     
       this.isLessThanCurrentDate = eventStartDateTime.isSameOrBefore(currentDateTime);
 
      if ((isBetweenEventTimes && isSameDay) || this.isLessThanCurrentDate) {
        modalRef.componentInstance.eventMessage = "Ongoing/Completed events cannot get updated."
      }else if(!isBetweenEventTimes && isSameDay){
        modalRef.componentInstance.eventMessage = "Cannot edit events on the day as the start date of the event."
      }

  }

  openInviteParticipantpopup() {
    const modalRef = this.modalService.open(InviteParticipantComponent);
    modalRef.componentInstance.fromParent = this.checkModelType;
  }

  viewAll() {
    this.ViewAllId.emit("2");
  }
}
