import { NavService } from "./../../../../../shared/services/nav.service";
import { LevelType } from "./../registration-list.model";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { RegistrationListModel } from "../registration-list.model";
import { RegistrationListService } from "../registration-list.service";
import { CommonService } from "../../../../../shared/common/commonService/common.service";
import {
  NgxDatatableSSRConfig,
  PaginationBaseModel,
  ResponseModel,
} from "../../../../../shared/common/commonModel/common.model";
import { catchError } from "rxjs";
import { EventlistService } from "../../../eventlist.service";
import { EventTypeEnum } from "src/app/components/create-event/create-event.model";


@Component({
  selector: "app-registration-list",
  templateUrl: "./registration-list.component.html",
  styleUrls: ["./registration-list.component.scss"],
})
export class RegistrationListComponent extends CommonService implements OnInit {
  @Output() passDatatoUserCard = new EventEmitter();
  @Input() eventDetails: any;

  break: boolean = false;
  levelType = LevelType;
  getScreenWidth: any;
  public company = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  checkModelType: String;
  ColumnMode = ColumnMode;
  hideTotalAndSearch: boolean = true;
  paginationBaseModel = new PaginationBaseModel();
  rows: any[] = [];
  emptyMessage: string;
  eventTypeEnum = EventTypeEnum;

  constructor(
    private modalService: NgbModal,
    public renderer: Renderer2,
    private config: NgbModalConfig,
    public registrationListService: RegistrationListService,
    public eventlistService: EventlistService,
    public navService: NavService
  ) {
    super(renderer);
    // this.config.centered = true;
    // this.config.size = "md";
  }

  ngOnInit(): void {
    this.registrationListService.hidetabletotalRecord.subscribe((res) => {
      this.hideTotalAndSearch = res;
    });
    this.navService.sendItem.subscribe((res: any) => {
      if (res.id != undefined) {
        this.initDatatable();
        this.loadListData();
      }
    });
  }

  initDatatable(): void {
    this.paginationBaseModel = new PaginationBaseModel();
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    if (!this.hideTotalAndSearch) {
      this.ngxDatatableSSRConfig.pageSize = 5;
    } else {
      this.ngxDatatableSSRConfig.pageSize = 10;
    }
    this.paginationBaseModel = {
      sortColumn: null,
      sortDirection: 0,
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      eventId: this.eventlistService.eventId,
    };
  }

  loadListData() {
    this.registrationListService
      .getAllRegisteredParticipantData(this.paginationBaseModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        if (res?.isSuccess === true) {
          this.updateTableData(res, res?.response);
          this.rows = res.response;
          this.emptyMessage = res?.message;
          this.ngxDatatableSSRConfig.totalRecords =
            res?.totalRecords == null ? 0 : res?.totalRecords;
        }
      });
  }

  handleError(err: any): any {
    // throw new Error('Method not implemented.');
  }

  onPageChanged(pageNum: number) {
    this.ngxDatatableSSRConfig.rows = [];
    this.onPageChange(pageNum);
    this.paginationBaseModel.pageNumber =
      this.ngxDatatableSSRConfig.currentPageNumber;
    this.loadListData();
  }

  getSingleuserValue(value: any) {
    this.passDatatoUserCard.emit(value);
    this.registrationListService.isSingleUserCardVisible.next(true);
    this.registrationListService.SingleUser.next(value);
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenWidth < 500 ? (this.break = true) : (this.break = false);
  }

  clearSearchText() {
    this.ngxDatatableSSRConfig.searchText = "";
    this.sortTableData();
  }

  sortTableData() {
    this.paginationBaseModel.searchText = this.ngxDatatableSSRConfig.searchText;
    this.loadListData();
  }

  ngOnChanges(){
    this.eventDetails
  }
}
