import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  public show         : boolean = false;
  public loginForm    : FormGroup;
  public errorMessage : any;

  constructor(
    // public authService: AuthService,
    public router :Router,
    private fb    : FormBuilder) 
    {
      this.loginForm = this.fb.group({
        firstName: ['', [Validators.required]],
        lastName : ['', [Validators.required]],
        email    : ['', [Validators.required, Validators.email]],
        password : ['', Validators.required],
        confirm_password: ['', Validators.required],
      });
   }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }
  
  // Login With Google
  loginGoogle() {
    // this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
    // this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    // this.authService.signInFacebok();
  }

  // Simple Login
  login() {
    this.router.navigate(['/admin/usermanagement']);
    // this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
  }

  openLoginForm(){
    this.router.navigate(['/login']);
  }
}
