import { LeaderboardListService } from "./../leaderboard-list.service";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { catchError } from "rxjs";
import { EventlistService } from "src/app/components/EventList/eventlist.service";
import {
  NgxDatatableSSRConfig,
  PaginationBaseModel,
  ResponseModel,
} from "src/app/shared/common/commonModel/common.model";
import { CommonService } from "src/app/shared/common/commonService/common.service";
import { NavService } from "src/app/shared/services/nav.service";
import { RegistrationListModel } from "../../registration-list.model";
import { RegistrationListService } from "../../registration-list.service";
import { LeaderboardService } from "../../leaderboard/leaderboard.service";

export class companyDB {}

@Component({
  selector: "app-leaderboard-list",
  templateUrl: "./leaderboard-list.component.html",
  styleUrls: ["./leaderboard-list.component.scss"],
})
export class LeaderboardListComponent extends CommonService implements OnInit {
  @Output() passDatatoUserCard = new EventEmitter();

  public company = [];
  rows: RegistrationListModel[] = [];
  break: boolean = false;
  getScreenWidth: any;
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  checkModelType: String;
  ColumnMode = ColumnMode;
  paginationBaseModel = new PaginationBaseModel();
  emptyMessage: string;

  constructor(
    public renderer: Renderer2,
    public navService: NavService,
    public registrationListService: RegistrationListService,
    public leaderboardListService: LeaderboardListService,
    public eventlistService: EventlistService,
    public leaderboardService: LeaderboardService
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.onWindowResize();
    this.navService.sendItem.subscribe((res: any) => {
      if (res.id != undefined) {
        this.initDatatable();
        this.loadListData();
      }
    });
  }

  initDatatable(): void {
    this.paginationBaseModel = new PaginationBaseModel();
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.paginationBaseModel = {
      sortColumn: null,
      sortDirection: 0,
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      eventId: this.eventlistService.eventId,
    };
  }

  loadListData() {
    this.leaderboardListService
      .getAllLeaderBoardData(this.paginationBaseModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        if (res?.isSuccess === true) {
          this.updateTableData(res, res?.response.participants);
          this.emptyMessage = res?.message;
          this.rows = res.response == "" ? [] : res.response.participants;
          this.leaderboardService.rows.next(this.rows);
          this.ngxDatatableSSRConfig.totalRecords =
            res?.totalRecords == null ? 0 : res?.totalRecords;
        }
      });
  }

  handleError(err: any): any {
    // throw new Error('Method not implemented.');
  }

  onPageSizeChange(pageSize: number) {
    this.ngxDatatableSSRConfig.rows = [];
    this.onPageSizeChanged(pageSize);
    this.initDatatable();
    this.loadListData();
  }

  onPageChanged(pageNum: number) {
    this.ngxDatatableSSRConfig.rows = [];
    this.onPageChange(pageNum);
    this.initDatatable();
    this.loadListData();
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    console.log(this.getScreenWidth);
    if (this.getScreenWidth < 500) {
      this.break = true;
    } else {
      this.break = false;
    }
  }

  clearSearchText() {
    this.ngxDatatableSSRConfig.searchText = "";
    this.sortTableData();
  }

  sortTableData() {
    this.paginationBaseModel.searchText = this.ngxDatatableSSRConfig.searchText;
    this.loadListData();
  }

  getSingleuserValue(value: any) {
    // this.passDatatoUserCard.emit(value.selected[0]);
    // this.registrationListService.isSingleUserCardVisible.next(true);
    // this.registrationListService.SingleUser.next(value.selected[0]);
    this.passDatatoUserCard.emit(value);
    this.registrationListService.isSingleUserCardVisible.next(true);
    this.registrationListService.SingleUser.next(value);

  }
}
