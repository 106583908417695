import { StorageService } from './../../../../services/storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  accountDetails: any;

  constructor(public storageService : StorageService) { }

  ngOnInit() {
    this.accountDetails = this.storageService.retrieve("userAccountDetails")
  }

}
