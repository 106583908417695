import { ResponseModel } from "src/app/shared/common/commonModel/common.model";
import {
  ActiveDeactiveUserModel,
  CreateUserModel,
  EditUserModel,
  UserRole,
} from "./../create-user.model";
import {
  Component,
  Input,
  OnInit,
  Renderer2,
  EventEmitter,
  Output,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/shared/common/commonService/common.service";
import { CreateUserService } from "./../create-user.service";
import { catchError, throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ConfirmPasswordValidator } from "../../auth/new-password/validation";
import * as moment from "moment";

declare var require;
const Swal = require("sweetalert2");

@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.scss"],
})
export class CreateUserComponent extends CommonService implements OnInit {
  @Input() fromParent;
  @Input() editUser: CreateUserModel;
  isReloadAfterSave: EventEmitter<boolean> = new EventEmitter();
  public createUserForm: FormGroup;
  public errorMessage: any;
  public createUserModel = new CreateUserModel();
  public validate = false;
  public editUserModel = new EditUserModel();
  editUserData: EditUserModel[];
  userRole = UserRole;
  fieldDisabled: boolean = false;
  activeDeactiveUserModel = new ActiveDeactiveUserModel();
  title: string;

  constructor(
    // public authService: AuthService,
    public router: Router,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    public renderer: Renderer2,
    public createUserService: CreateUserService,
    public toaster: ToastrService
  ) {
    super(renderer);
    this.createUserForm = this.fb.group(
      {
        firstName: ["", [Validators.required]],
        lastName: ["", [Validators.required]],
        email: ["", [Validators.required, Validators.email]],
        userRole: ["", [Validators.required]],
        createPassword: [""],
        confirmPassword: [""],
      },
      {
        validator: ConfirmPasswordValidator(
          "createPassword",
          "confirmPassword"
        ),
      }
    );
  }

  ngOnInit() {
    console.log("dfd", this.fromParent);
    if (this.fromParent == "EditUser") {
      this.createUserModel = this.editUser;
      this.InputDisabled();
    }
  }

  InputDisabled(): boolean {
    return this.createUserModel.userRoleId == 3;
  }

  closePopUp() {
    this.activeModal.dismiss();
  }

  changeUserActiveStatus(event) {
    if (event.target.checked) {
      this.title = "Do you want to Deactivate user?";
    } else {
      this.title = "Do you want to activate user?";
    }
    Swal.fire({
      title: this.title,
      //showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.activeDeactiveUserModel.userId = this.editUser.id;
        this.activeDeactiveUserModel.isActive = !event.target.checked;
        this.createUserService
          .activeDeactiveUser(this.activeDeactiveUserModel)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res: ResponseModel) => {
            if (res.isSuccess) {
              this.toaster.error(res.message, null, { timeOut: 900 });
              this.isReloadAfterSave.emit(true);
            }
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        event.target.checked = !event.target.checked;
      } else if (result.dismiss == "backdrop") {
        event.target.checked = !event.target.checked;
      }
    });
  }

  //# REGION CREATE USER AND EDIT USER POST START

  CreateEditUserData() {
    this.validate = !this.validate;
    if (this.createUserForm.valid) {
      if (this.fromParent == "CreateUser") {
        // this.createUserModel.registrationDate = moment(new Date()).format("yyyy-MM-DD"); // for date conversion to string
        this.createUserModel.firstName = this.createUserForm.value.firstName;
        this.createUserModel.lastName = this.createUserForm.value.lastName;
        this.createUserModel.userRoleId = this.createUserForm.value.userRole;
        this.createUserService
          .createUser(this.createUserModel)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res: ResponseModel) => {
            if (res.isSuccess) {
              this.activeModal.close();
              this.isReloadAfterSave.emit(true);
            } else {
              this.toaster.error(res.message, null, { timeOut: 700 });
            }
            // this.createUserModel = res;
          });
      } else if (this.fromParent == "EditUser") {
        this.editUserModel.email = this.createUserModel.email;
        this.createUserService
          .editUser(this.editUserModel)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res: ResponseModel) => {
            if (res.isSuccess) {
              this.activeModal.close();
              this.isReloadAfterSave.emit(true);
            } else {
              this.toaster.error(res.message, null, { timeOut: 700 });
            }
          });
      }
    } else {
      this.toaster.error("Form is Invalid!", null, { timeOut: 700 });
    }
  }

  //# REGION CREATE USER AND EDIT USER POST END

  newUpdatedValue(value, fieldName) {
    switch (fieldName) {
      case "firstName": {
        this.editUserModel.firstName = value;
        break;
      }
      case "lastName": {
        this.editUserModel.lastName = value;
        break;
      }
      case "userRole": {
        this.editUserModel.userRoleId = value;
        break;
      }
    }
  }

  private handleError(error: any) {
    this.toaster.error(error.error.errors[0], null, { timeOut: 700 });
    // this.toaster.error(error.error.message);
    return throwError(error);
  }

  public submit() {}
}
