import { PopulateEnumPipe } from './shared/pipes/populate-enum.pipe';
import { PermissionsComponent } from './components/Admin/permissions/permissions.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { UsermanagementModule } from './components/Admin/usermanagement/usermanagement.module';
import { SignUpComponent } from './components/auth/sign-up/sign-up.component';
import { ForgetPasswordComponent } from './components/auth/forget-password/forget-password.component';
import { EnterOtpComponent } from './components/auth/enter-otp/enter-otp.component';
import { NewPasswordComponent } from './components/auth/new-password/new-password.component';
import { CreateUserModule } from './components/User_Information/create-user/create-user.module';
import { CreateUserComponent } from './components/User_Information/create-user/create-user.component';
import { CreateEventComponent } from './components/create-event/create-event.component';
import { InviteParticipantModule } from './components/EventList/invite-participant/invite-participant/invite-participant.module';
import { RegistrationListModule } from './components/EventList/EvenlistTabs/registrationList/registration-list/registration-list.module';
import { RegistrationListComponent } from './components/EventList/EvenlistTabs/registrationList/registration-list/registration-list.component';
import { RegistraionUserComponent } from './components/EventList/EvenlistTabs/registrationList/registraion-user/registraion-user.component';
import { ImgCropperModule } from './components/img-cropper/img-cropper.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OverviewModule } from './components/EventList/EvenlistTabs/registrationList/overview/overview/overview.module';
import { OverviewComponent } from './components/EventList/EvenlistTabs/registrationList/overview/overview/overview.component';
import { LeaderboardModule } from './components/EventList/EvenlistTabs/registrationList/leaderboard/leaderboard/leaderboard.module';
import { LeaderboardComponent } from './components/EventList/EvenlistTabs/registrationList/leaderboard/leaderboard/leaderboard.component';
import { LeaderboardListModule } from './components/EventList/EvenlistTabs/registrationList/leaderboard-list/leaderboard-list/leaderboard-list.module';
import { LeaderboardListComponent } from './components/EventList/EvenlistTabs/registrationList/leaderboard-list/leaderboard-list/leaderboard-list.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { EventDetailsComponent } from './components/EventList/event-details/event-details.component';
import { EventStatusComponent } from './components/EventList/EvenlistTabs/registrationList/overview/event-status/event-status.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    PermissionsComponent,
    SignUpComponent,
    ForgetPasswordComponent,
    EnterOtpComponent,
    NewPasswordComponent,
    CreateUserComponent,
    CreateEventComponent,
    RegistrationListComponent,
    RegistraionUserComponent,
    OverviewComponent,
    LeaderboardComponent,
    LeaderboardListComponent,
    PopulateEnumPipe,
    EventDetailsComponent,
    EventStatusComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NgApexchartsModule,

    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    ImageCropperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    UsermanagementModule,
    CreateUserModule,
    InviteParticipantModule,
    RegistrationListModule,
    ImgCropperModule,
    LeaderboardModule,
    LeaderboardListModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
