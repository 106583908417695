<div class="container-fluid p-4">
    <div class="row">
      <div class="col-12">     
            <div class="login-main"> 
              <form class="theme-form" ngNativeValidate [formGroup]="createUserForm">
                <span *ngIf="fromParent == 'CreateUser'" class="color-orange f-26">Create User
                    <!-- <i class="fa fa-close pull-right"></i> -->
                </span>
                <span *ngIf="fromParent == 'EditUser'" class="color-orange f-26">Edit User
                    <!-- <i class="fa fa-close pull-right"></i> -->
                </span>
                <div class="form-group row mb-0 gutter-5rem">
                  <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
                    <label class="col-form-label color-black">First Name</label>
                    <input  class="form-control" title="" [attr.disabled]="InputDisabled() == true? true : null" [ngClass]="{'background-faintGrey': InputDisabled() == true }"  (keydown.enter)="onKeydown($event, '#LastName')" [ngModel]="createUserModel.firstName" (ngModelChange)="newUpdatedValue($event,'firstName')" autocomplete="nope" type="text" required="true" placeholder="John" formControlName="firstName" OnlyAlphabetsSpaceAllowed>
                  </div>
                  <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6 mb-1">
                    <label class="col-form-label color-black">Last Name</label>
                    <input class="form-control" title="" [attr.disabled]="InputDisabled() == true? true : null" [ngClass]="{'background-faintGrey': InputDisabled() == true }" (keydown.enter)="onKeydown($event, '#Email')" id="LastName" [ngModel]="createUserModel.lastName" (ngModelChange)="newUpdatedValue($event,'lastName')" autocomplete="nope" type="text" required="true" placeholder="Doe" formControlName="lastName" OnlyAlphabetsSpaceAllowed>
                  </div>
                </div>
                <div class="form-group row mb-0 gutter-5rem">
                    <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
                      <label class="col-form-label color-black">Email Address</label>
                      <input class="form-control" title="" [readOnly]="fromParent == 'EditUser'" [attr.disabled]="InputDisabled() == true? true : null" [ngClass]="{'background-faintGrey': InputDisabled() == true }" (keydown.enter)="onKeydown($event, '#UserRole')" id="Email" [(ngModel)]="createUserModel.email" autocomplete="nope" type="email" required="true" placeholder="Johndoe@email.com" formControlName="email"> 
                    </div>
                    <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6 mb-1">
                      <label class="col-form-label color-black">User Role</label>
                      <select class="p-2" title="" [attr.disabled]="InputDisabled() == true? true : null" [ngClass]="{'background-faintGrey': InputDisabled() == true }"  (keydown.enter)="onKeydown($event, '#CreatePass')" id="UserRole" [ngModel]="createUserModel.userRoleId" (ngModelChange)="newUpdatedValue($event,'userRole')" style="min-height: 32px;max-height: 32px;" required="true" placeholder="Enter User Role" formControlName="userRole">
                        <option *ngFor="let item of userRole | populateEnum" [value]="item.key" [ngClass]="{'d-none': item.key == 3}">
                          {{item.value}}
                        </option>
                      </select>
                    </div>
                  </div>
                <div class="form-group row mb-0 gutter-5rem" [ngStyle]="{'padding-top': fromParent == 'EditUser' ? '7px' : '' }">
                    <div  class="col-md-6 col-sm-12 col-lg-6 col-xl-6 mb-1">
                        <label *ngIf="fromParent != 'EditUser'" class="col-form-label color-black">Create Password</label>
                        <input *ngIf="fromParent != 'EditUser'" class="form-control" title="" [attr.disabled]="InputDisabled() == true? true : null" [ngClass]="{'background-faintGrey': InputDisabled() == true }" (keydown.enter)="onKeydown($event, '#ConfirmPass')" id="CreatePass" [(ngModel)]="createUserModel.password" autocomplete="nope" type="text" formControlName="createPassword" [required]="fromParent != 'EditUser'" placeholder="Password must be 8 char long">
                    </div>
                    <div *ngIf="fromParent == 'EditUser'" class="col-md-6 col-sm-12 col-lg-6 col-xl-6 d-flex align-self-end mt-1 mt-lg-0">
                        <input class="" [checked]="!editUser.isActive" (change)="changeUserActiveStatus($event)" id="checkbox1" type="checkbox">
                        <label for="checkbox1" class="col-form-label color-black m-l-10 mb-1 cursor-pointer">Deactivate User</label>
                    </div>
                </div>
                <div class="form-group row mb-0 gutter-5rem">
                    <div *ngIf="fromParent != 'EditUser'" class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
                        <label class="col-form-label color-black">Confirm Password</label>
                        <input class="form-control" title="" [attr.disabled]="InputDisabled() == true? true : null" [ngClass]="{'background-faintGrey': InputDisabled() == true }" (keydown.enter)="onKeydown($event, '#Save')" id="ConfirmPass" [(ngModel)]="createUserModel.confirmPassword" autocomplete="nope" type="text" formControlName="confirmPassword" [required]="fromParent != 'EditUser'" placeholder="Both password must match">
                    </div>
                    <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
                    </div>
                </div>
                <div class="form-group row mb-0 mt-4 gutter-5rem">
                    <div class="col-3 col-md-6 col-sm-12 col-lg-6 col-xl-6"></div>
                    <div class="col-9 col-md-6 col-sm-12 mt-sm-2 col-lg-6 col-xl-6 mt-4 d-flex justify-content-between">
                      <button [ngClass]="{'vis-Hidden': InputDisabled()}" class="btn-signIn background-orange w-100px px-1 py-2 border-0"
                        type="submit" (click)="CreateEditUserData()"><span class="f-16">Save</span>
                      </button>
                      <button id="Save" class="btn-signIn background-grey w-100px px-1 py-2 border-0"
                        type="button" (click)="closePopUp()"><span class="f-16">Cancel</span>
                      </button>
                    </div>
                </div>
              </form>
            </div>
      </div>
    </div>
  </div>