import { ResponseModel } from 'src/app/shared/common/commonModel/common.model';
import { PaginationBaseModel } from './../../shared/common/commonModel/common.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { DataService } from './../../shared/services/data.service';


@Injectable({
  providedIn: 'root'
})
export class EventlistService {

  updateEventData = new BehaviorSubject<boolean>(undefined);

  eventId:number;
  constructor(private service : DataService) { }

  GetEvents(eventlistModel : PaginationBaseModel): Observable <ResponseModel>{
    let url = '/api/dominative/admin/event/get';
    const myJSON = JSON.stringify(eventlistModel);
    return this.service.post(url,eventlistModel,true).pipe<ResponseModel>(tap((response: any)=>{
      return response;
    }));
  }

}