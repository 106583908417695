<div class="background-light-grey boxShadow b-r-5 p-4" style="height: 100vh;">
    <div class="container-fluid p-0">
     <div class="mb-3">
         <button class="btn btn-block btn-signIn background-orangered px-4 py-2"
         type="button" (click)="backToRegistrationList(false)" ><span class="font-rubik font-weight-bold">Back</span>
         </button>
     </div>
     <div class="card Cardboxshdow col-xl-6 col-md-12 col-lg-12 border-r-15">
     <div class="card-body "> 
         <div class="card-body-head">
             <div class="d-flex flex-wrap">
                 <div class="col-12 col-lg-4 col-md-4 ">
                    <div class="ratio ratio-1x1 Imgbox">
                        <img class="img-widht-height" [src]="registrationListModel.profilePhoto.url" onerror="this.src='../../../../../../../assets/images/dashboard/default.png'" alt="">
                    </div>
                 </div>
                 <div class="col-12 col-lg-8 col-md-8 ps-lg-4 ps-md-4 pe-lg-1 pe-md-3 d-flex flex-column justify-content-between">
                     <div class="ps-lg-1 ps-md-1">
                         <span class="color-orangered f-24">{{registrationListModel.user}}</span>
                         <div class="d-flex justify-content-between color-black f-16 my-2">
                             <div class="">Participant Number:</div>
                             <div><span class="" style="margin-right: 15px;">{{registrationListModel.participantNo}}</span></div>
                         </div>

                         <div class="d-flex justify-content-between color-black f-16 mb-2">
                             <div class="">Registered On: &nbsp;</div>
                             <div class=""><span>{{registrationListModel.registrationDate | date: 'MMMM d, y'}}</span></div>
                         </div>

                         <div class="d-flex justify-content-between color-black  f-16 mb-2">
                             <div class="">Email:</div>
                             <div class="ms-1 email"><span>{{registrationListModel.email}}</span></div>
                         </div>
                     </div>
                     <div class="ps-lg-1 ps-md-1">
                         <button class="btn-signIn b-r-10 py-1" [ngClass]="{'btn-skyBlue':registrationListModel.levelType == levelType[1],'btn-orange':registrationListModel.levelType == levelType[2],'btn-lightRed':registrationListModel.levelType == levelType[3]}"
                             type="button"><span class="f-14">{{registrationListModel.levelType}}</span>
                         </button>
                     </div>
                 </div>
             </div>
         </div>
         <hr class="my-4 opacity-100">
         <div class="card-body-footer">
             <div class="d-flex" [ngClass]="{'justify-content-around':eventDetails?.eventTypeId != eventTypeEnum.Marathon && eventDetails?.eventTypeId != eventTypeEnum.Running,'justify-content-center':eventDetails?.eventTypeId == eventTypeEnum.Marathon || eventDetails?.eventTypeId == eventTypeEnum.Running}">
                 <div class="">
                     <div>
                         <svg *ngIf="eventDetails?.eventTypeId != eventTypeEnum.Marathon && eventDetails?.eventTypeId != eventTypeEnum.Running" xmlns="http://www.w3.org/2000/svg" width="75.586" height="42.246" viewBox="0 0 75.586 35.413">
                             <g id="Group_40773" data-name="Group 40773" transform="translate(0.951 1.5)">
                               <path id="Path_555" data-name="Path 555" d="M1,9.844c4.535,3.721,15.46,8.045,22.6-2.982a1.153,1.153,0,0,1,1.9-.116c5.068,6.336,15.8,12.434,25.306-.069a1.108,1.108,0,0,1,1.833.052c6.412,9.183,15.591,9.312,21.941,3.114" transform="translate(-1 6.035)" fill="none" stroke="#1b1b1b" stroke-width="3"/>
                               <path id="Path_556" data-name="Path 556" d="M1,14.722c4.535,3.721,15.46,8.045,22.6-2.982a1.153,1.153,0,0,1,1.9-.116c5.068,6.336,15.8,12.434,25.306-.069a1.108,1.108,0,0,1,1.833.052c6.412,9.183,15.591,9.312,21.941,3.114" transform="translate(-1 13.408)" fill="none" stroke="#1b1b1b" stroke-width="3"/>
                               <path id="Path_557" data-name="Path 557" d="M1,4.965C5.535,8.686,16.46,13.01,23.6,1.983a1.153,1.153,0,0,1,1.9-.116C30.576,8.2,41.3,14.3,50.813,1.8a1.108,1.108,0,0,1,1.833.052c6.412,9.183,15.591,9.312,21.941,3.114" transform="translate(-1 -1.338)" fill="none" stroke="#1b1b1b" stroke-width="3"/>
                             </g>
                           </svg>
                           <!-- <svg *ngIf="eventDetails?.eventTypeId == eventTypeEnum.Marathon" xmlns="http://www.w3.org/2000/svg" width="75.586" height="35.413" viewBox="0 0 75.586 35.413">
                            <g id="Group_40773" data-name="Group 40773" transform="translate(0.951 1.5)">
                              <path id="Path_555" data-name="Path 555" d="M1,9.844c4.535,3.721,15.46,8.045,22.6-2.982a1.153,1.153,0,0,1,1.9-.116c5.068,6.336,15.8,12.434,25.306-.069a1.108,1.108,0,0,1,1.833.052c6.412,9.183,15.591,9.312,21.941,3.114" transform="translate(-1 6.035)" fill="none" stroke="#8c8c8c" stroke-width="3"/>
                              <path id="Path_556" data-name="Path 556" d="M1,14.722c4.535,3.721,15.46,8.045,22.6-2.982a1.153,1.153,0,0,1,1.9-.116c5.068,6.336,15.8,12.434,25.306-.069a1.108,1.108,0,0,1,1.833.052c6.412,9.183,15.591,9.312,21.941,3.114" transform="translate(-1 13.408)" fill="none" stroke="#8c8c8c" stroke-width="3"/>
                              <path id="Path_557" data-name="Path 557" d="M1,4.965C5.535,8.686,16.46,13.01,23.6,1.983a1.153,1.153,0,0,1,1.9-.116C30.576,8.2,41.3,14.3,50.813,1.8a1.108,1.108,0,0,1,1.833.052c6.412,9.183,15.591,9.312,21.941,3.114" transform="translate(-1 -1.338)" fill="none" stroke="#8c8c8c" stroke-width="3"/>
                            </g>
                          </svg> -->
                     </div>
                     <div class="text-center mt-3" *ngIf="eventDetails?.eventTypeId != eventTypeEnum.Marathon && eventDetails?.eventTypeId != eventTypeEnum.Running">
                         <span class="f-24 color-orange" [ngClass]="{'color-lighter-grey':eventDetails?.eventTypeId == eventTypeEnum.Marathon}">
                            {{registrationListModel.swimming | number:'1.0-2'}}
                         </span>
                     </div>
                 </div>
                 <div class="">
                     <div>
                         <svg *ngIf="eventDetails?.eventTypeId != eventTypeEnum.Marathon && eventDetails?.eventTypeId != eventTypeEnum.Running" xmlns="http://www.w3.org/2000/svg" width="70.046" height="42.246" viewBox="0 0 70.046 42.246">
                             <g id="Group_40774" data-name="Group 40774" transform="translate(1.5 1.5)">
                               <path id="Path_558" data-name="Path 558" d="M14.105,6.216a13.9,13.9,0,1,0,8.538,2.335" transform="translate(-1 5.231)" fill="none" stroke="#1b1b1b" stroke-width="3"/>
                               <path id="Path_559" data-name="Path 559" d="M6.961,27.164,13.283,6.191m0,0H41.521m-28.238,0V1M41.521,6.191l6.322,20.973M41.521,6.191l-1.44-4.433A1.1,1.1,0,0,0,39.038,1H32.46M13.283,1H9.49m3.793,0h5.69" transform="translate(6.121 -1)" fill="none" stroke="#1b1b1b" stroke-width="3"/>
                               <path id="Path_560" data-name="Path 560" d="M33.578,6.216a13.9,13.9,0,1,1-8.538,2.335" transform="translate(20.362 5.231)" fill="none" stroke="#1b1b1b" stroke-width="3"/>
                             </g>
                           </svg>
                           <!-- <svg *ngIf="eventDetails?.eventTypeId == eventTypeEnum.Marathon" xmlns="http://www.w3.org/2000/svg" width="70.046" height="42.246" viewBox="0 0 70.046 42.246">
                            <g id="Group_40774" data-name="Group 40774" transform="translate(1.5 1.5)">
                              <path id="Path_558" data-name="Path 558" d="M14.105,6.216a13.9,13.9,0,1,0,8.538,2.335" transform="translate(-1 5.231)" fill="none" stroke="#8c8c8c" stroke-width="3"/>
                              <path id="Path_559" data-name="Path 559" d="M6.961,27.164,13.283,6.191m0,0H41.521m-28.238,0V1M41.521,6.191l6.322,20.973M41.521,6.191l-1.44-4.433A1.1,1.1,0,0,0,39.038,1H32.46M13.283,1H9.49m3.793,0h5.69" transform="translate(6.121 -1)" fill="none" stroke="#8c8c8c" stroke-width="3"/>
                              <path id="Path_560" data-name="Path 560" d="M33.578,6.216a13.9,13.9,0,1,1-8.538,2.335" transform="translate(20.362 5.231)" fill="none" stroke="#8c8c8c" stroke-width="3"/>
                            </g>
                          </svg> -->
                          
                         </div>
                         <div class="text-center mt-3" *ngIf="eventDetails?.eventTypeId != eventTypeEnum.Marathon && eventDetails?.eventTypeId != eventTypeEnum.Running">
                             <span class="f-24 color-orange" [ngClass]="{'color-lighter-grey':eventDetails?.eventTypeId == eventTypeEnum.Marathon}">
                                {{registrationListModel.cycling | number:'1.0-2'}}
                             </span>
                         </div>
                     </div>
                 <div class="">
                     <div>
                         <svg xmlns="http://www.w3.org/2000/svg" width="53.475" height="42.246" viewBox="0 0 53.475 44.772">
                             <path id="Path_561" data-name="Path 561" d="M5.863,10.174c-1.363-.237-4.515.64-4.8,3.2-.356,3.2.355,6.932,12.976,12.62,1.3.533,4.764,4.751,7.821,9.421,6.4,9.776,22.068,7.563,25.241,5.51,1.454-.941,3.648-2.745,4.228-4.621M5.863,10.174C11.409.22,17.18.457,19.55,1.464c-1.6,4.088,1.244,7.288,4.444,8.71,2.56,1.138,6.043-.474,7.466-1.422q.569,1.984,1.1,3.733m-26.7-2.311c1.422,2.488,4.452,8.215,7.466,9.421,6.221,2.489,6.782,5.888,12.8,13.509,5.332,6.754,17.927,4.862,25.2,3.2m0,0c.625-2.024-.628-4.133-6.716-5.332C41,30.26,38.48,28.543,35.359,20.661m-2.8-8.176-4.481,1.6m4.481-1.6c.518,1.694.657,2.874,1.136,4.266m0,0-3.839,1.6m3.839-1.6c.449,1.307,1.238,2.843,1.661,3.91m0,0-3.9,1.778" transform="translate(0.487 0.533)" fill="none" stroke="#1b1b1b" stroke-width="3"/>
                           </svg>
                     </div>
                     <div class="text-center mt-3">
                         <span class="f-24 color-orange">
                            {{registrationListModel.running | number:'1.0-2'}}
                         </span>
                     </div>
                 </div>
             </div>
         </div>
     </div>
     </div>
    </div>
 </div>