<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 px-0">
      <div class="card mb-0">
        <div class="d-flex p-3 align-items-center justify-content-end py-3">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 px-0">
            <div class="col-xl-8 d-flex pull-right">
              <span class="align-self-center me-3">Search:</span>
              <input
                type="text"
                class="form-control border-faint py-2"
                (keydown.enter)="sortTableData()"
                [(ngModel)]="ngxDatatableSSRConfig.searchText"
              />
              <svg
                (click)="clearSearchText()"
                *ngIf="ngxDatatableSSRConfig?.searchText.length > 0"
                class="search-cross-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 22.64 22.64"
              >
                <path
                  d="M12.571,11.321l9.81-9.81A.884.884,0,0,0,21.13.26l-9.81,9.81L1.51.26A.884.884,0,0,0,.259,1.511l9.81,9.81-9.81,9.81A.884.884,0,0,0,1.51,22.382l9.81-9.81,9.81,9.81a.884.884,0,0,0,1.251-1.251Z"
                  transform="translate(0 -0.001)"
                  fill="#000"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="card-body px-3 pb-1 pt-0 position-relative">
          <h5
            class="position-absolute top-50 start-50 translate-middle color-orange"
            *ngIf="ngxDatatableSSRConfig?.rows.length == 0 || null || undefined"
          >
            {{ emptyMessage }}
          </h5>
          <ngx-datatable
            class="bootstrap leaderboard"
            [messages]="{ emptyMessage: 'No data to display' }"
            [rows]="ngxDatatableSSRConfig?.rows"
            [loadingIndicator]="loadingIndicator"
            [columnMode]="'force'"
            [headerHeight]="55"
            [limit]="ngxDatatableSSRConfig?.pageSize"
            [footerHeight]="45"
            [rowHeight]="50"
            [reorderable]="reorderable"
            [offset]="ngxDatatableSSRConfig?.tableOffset"
            [scrollbarH]="true"
            [scrollbarV]="false"
            [selectionType]="'checkbox'"
          >
            <!-- (select)="getSingleuserValue($event)" -->

            <ngx-datatable-column
              headerClass="px-2"
              cellClass="px-2 p-t-5"
              [width]="35"
              prop="isActive"
              name="Active"
              [sortable]="true"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
                let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn"
              >
                <div class="d-flex align-items-center">
                  <!-- <span>Active</span> -->
                  <input
                    class="checkboxx"
                    type="checkbox"
                    [checked]="allRowsSelected"
                    (change)="selectFn(!allRowsSelected)"
                  />
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
              >
                <input
                  class="checkboxx"
                  type="checkbox"
                  [checked]="isSelected"
                  (change)="onCheckboxChangeFn($event)"
                />
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              headerClass="px-0"
              cellClass="px-0"
              [width]="150"
              prop="user"
              name="User"
              [sortable]="true"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <div class="d-flex align-items-center" (click)="sort()">
                  <span>Name</span>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == 'asc'"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34456"
                        data-name="Path 34456"
                        d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                        transform="translate(-4.667 5)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == 'desc'"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34457"
                        data-name="Path 34457"
                        d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                        transform="translate(0 -4)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == undefined"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34456"
                        data-name="Path 34456"
                        d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                        transform="translate(-4.667 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_34457"
                        data-name="Path 34457"
                        d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                        transform="translate(-4.667 0)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <div (click)="getSingleuserValue(row)">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="145"
              prop="participantNo"
              name="Participant No"
              [sortable]="true"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                l
                et-sortDir="sortDir"
              >
                <div class="d-flex" (click)="sort()">
                  <span
                    >Participant <br *ngIf="break" />
                    Number</span
                  >
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == 'asc'"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34456"
                        data-name="Path 34456"
                        d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                        transform="translate(-4.667 5)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == 'desc'"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34457"
                        data-name="Path 34457"
                        d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                        transform="translate(0 -4)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == undefined"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34456"
                        data-name="Path 34456"
                        d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                        transform="translate(-4.667 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_34457"
                        data-name="Path 34457"
                        d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                        transform="translate(-4.667 0)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <div (click)="getSingleuserValue(row)">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="200"
              prop="email"
              name="Email"
              [sortable]="true"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <div class="d-flex align-items-center" (click)="sort()">
                  <span>Email</span>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == 'asc'"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34456"
                        data-name="Path 34456"
                        d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                        transform="translate(-4.667 5)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == 'desc'"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34457"
                        data-name="Path 34457"
                        d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                        transform="translate(0 -4)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == undefined"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34456"
                        data-name="Path 34456"
                        d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                        transform="translate(-4.667 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_34457"
                        data-name="Path 34457"
                        d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                        transform="translate(-4.667 0)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <span
                  ngClass=""
                  class="d-inline-block w-100 text-truncate color-gray"
                  title="{{ value }}"
                  (click)="getSingleuserValue(row)"
                >
                  {{ value }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="140"
              prop="registrationDate"
              name="Registration Date"
              [sortable]="true"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <div class="d-flex" (click)="sort()">
                  <span
                    >Registration <br *ngIf="break" />
                    Date</span
                  >
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == 'asc'"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34456"
                        data-name="Path 34456"
                        d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                        transform="translate(-4.667 5)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == 'desc'"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34457"
                        data-name="Path 34457"
                        d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                        transform="translate(0 -4)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == undefined"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34456"
                        data-name="Path 34456"
                        d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                        transform="translate(-4.667 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_34457"
                        data-name="Path 34457"
                        d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                        transform="translate(-4.667 0)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <span class="color-gray" (click)="getSingleuserValue(row)">{{
                  value | date : "MMMM d, y"
                }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="170"
              prop="country"
              name="Country"
              [sortable]="true"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <div class="d-flex align-items-center" (click)="sort()">
                  <span>Country</span>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == 'asc'"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34456"
                        data-name="Path 34456"
                        d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                        transform="translate(-4.667 5)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == 'desc'"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34457"
                        data-name="Path 34457"
                        d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                        transform="translate(0 -4)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == undefined"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34456"
                        data-name="Path 34456"
                        d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                        transform="translate(-4.667 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_34457"
                        data-name="Path 34457"
                        d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                        transform="translate(-4.667 0)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <span class="color-gray" (click)="getSingleuserValue(row)">{{
                  value
                }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="100"
              prop="rank"
              name="Rank"
              [sortable]="true"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-sort="sortFn"
                let-sortDir="sortDir"
              >
                <div class="d-flex align-items-center" (click)="sort()">
                  <span>Rank</span>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == 'asc'"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34456"
                        data-name="Path 34456"
                        d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                        transform="translate(-4.667 5)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == 'desc'"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34457"
                        data-name="Path 34457"
                        d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                        transform="translate(0 -4)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <svg
                    class="m-l-10"
                    *ngIf="sortDir == undefined"
                    id="Group_40876"
                    data-name="Group 40876"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18.667 28"
                  >
                    <g id="Group_40875" data-name="Group 40875">
                      <path
                        id="Path_34456"
                        data-name="Path 34456"
                        d="M22.992,5.008,18.325.342h0Q18.284.3,18.24.264C18.226.253,18.212.244,18.2.233S18.165.208,18.148.2,18.114.176,18.1.166L18.05.138,18,.112l-.05-.023L17.894.07,17.839.05,17.786.037,17.728.023,17.666.013,17.615.006a1.174,1.174,0,0,0-.23,0l-.051.007-.062.009-.058.014L17.162.05,17.106.07l-.052.019L17,.112,16.95.138,16.9.166c-.017.01-.034.02-.051.031s-.033.024-.05.037-.028.02-.042.031q-.044.036-.085.077h0L12.009,5.008a1.167,1.167,0,0,0,1.65,1.65l2.675-2.675V17.5a1.167,1.167,0,1,0,2.333,0V3.983l2.675,2.675a1.167,1.167,0,1,0,1.65-1.65Z"
                        transform="translate(-4.667 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_34457"
                        data-name="Path 34457"
                        d="M14.342,21.342l-2.675,2.675V10.5a1.167,1.167,0,0,0-2.333,0V24.017L6.658,21.342a1.167,1.167,0,1,0-1.65,1.65l4.667,4.667h0c.027.027.055.052.085.077l.042.031c.017.012.033.025.05.037s.034.021.051.031l.047.028.053.026.05.023.052.019.055.019.053.014.058.014.062.009.051.007a1.174,1.174,0,0,0,.23,0l.051-.007.062-.009.058-.014.053-.014.055-.019.052-.019.05-.023.053-.026.047-.028c.017-.01.034-.02.051-.031s.033-.024.05-.037l.042-.031c.029-.024.057-.049.084-.076h0l4.667-4.667a1.167,1.167,0,1,0-1.65-1.65Z"
                        transform="translate(-4.667 0)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
              >
                <div (click)="getSingleuserValue(row)">
                  #{{ value == null ? "NA" : value }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                ngx-datatable-footer-template
              >
                <div
                  class="col-12 my-2 d-flex justify-content-center justify-content-lg-end justify-content-md-end justify-content-xl-end justify-content-xxl-end flex-wrap"
                >
                  <div
                    class="d-flex pagination justify-content-lg-end justify-content-md-end flex-column flex-md-row flex-wrap"
                  >
                    <div
                      class="page-size-control d-flex align-items-center justify-content-center me-md-5 me-lg-5 me-xl-5 me-xxl-5 f-12 color-orange mb-md-0 mb-lg-0 mt-lg-0 mt-md-0"
                    >
                      Show
                      <select
                        style="width: fit-content"
                        class="page-size-dropdown form-control mx-2 b-r-0 my-1 my-md-0 my-lg-0 my-xl-0 my-xxl-0"
                        [(ngModel)]="paginationBaseModel.pageSize"
                        (ngModelChange)="onPageSizeChange($event)"
                      >
                        <option
                          *ngFor="
                            let opt of ngxDatatableSSRConfig?.limitOptions
                          "
                          [ngValue]="opt.value"
                        >
                          {{ opt.key }}
                        </option>
                      </select>
                      entries
                    </div>
                    <div class="pagination-control-leaderboard f-14">
                      <ngb-pagination
                        #ngbPage
                        [boundaryLinks]="false"
                        [collectionSize]="ngxDatatableSSRConfig?.totalRecords"
                        [pageSize]="
                          ngxDatatableSSRConfig?.pageSize == 0
                            ? 1
                            : ngxDatatableSSRConfig?.pageSize
                        "
                        [page]="
                          ngxDatatableSSRConfig?.currentPageNumber == 0
                            ? 1
                            : ngxDatatableSSRConfig?.currentPageNumber
                        "
                        [maxSize]="5"
                        (pageChange)="onPageChanged($event)"
                      >
                        <ng-template ngbPaginationPrevious
                          >Previous</ng-template
                        >
                        <ng-template ngbPaginationNext>Next</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
