import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgCropperRoutingModule } from './img-cropper-routing.module';
import { ImgCropperComponent } from './img-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    ImgCropperComponent
  ],
  imports: [
    CommonModule,
    ImgCropperRoutingModule,
    ImageCropperModule
  ]
})
export class ImgCropperModule { }