import { EnterOtpComponent } from './components/auth/enter-otp/enter-otp.component';
import { NewPasswordComponent } from './components/auth/new-password/new-password.component';
import { ForgetPasswordComponent } from './components/auth/forget-password/forget-password.component';
import { SignUpComponent } from './components/auth/sign-up/sign-up.component';
import { LoginComponent } from './components/auth/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component:LoginComponent
  },
  {
    path: 'signup',
    component:SignUpComponent
  },
  {
    path: 'enterOtp',
    component:EnterOtpComponent
  },
  {
    path: 'forgotpassword',
    component:ForgetPasswordComponent
  },
  {
    path: 'account/reset-password',
    component:NewPasswordComponent
  },
  {
    path: '',
    component: ContentComponent,
    children: content
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
