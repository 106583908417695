import { StorageService } from "./storage.service";
import { EventEmitter, Injectable, OnDestroy, Output } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent, Observable } from "rxjs";
import { takeUntil, debounceTime, tap, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { LoginService } from "src/app/components/auth/login.service";

// Menu
export interface Menu {
  id?: number;
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  sendItem = new BehaviorSubject<any>(undefined);
  updateEvent = new BehaviorSubject<any>(undefined);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // Full screen
  public fullScreen: boolean = false;
  localEventData?: any[] = [];

  constructor(
    private router: Router,
    public storageService: StorageService,
    public loginService: LoginService
  ) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
this.updateEventData()

  }
  handleError(err: any): any {
    throw new Error(err);
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "Admin",
      icon: "admin",
      type: "sub",
      active: true,
      children: [
        {
          path: "/admin/usermanagement",
          title: "User Management",
          type: "link",
        },
        { path: "/admin/permissions", title: "Permissions", type: "link" },
      ],
    },
    {
      title: "Event List",
      icon: "eventList",
      type: "sub",
      active: true,
      children: this.getChildData(),
    },
  ];

  MEGAMENUITEMS: Menu[] = [
    {
      title: "Error Pages",
      type: "sub",
      active: true,
      children: [
        {
          path: "javascript:void(0);",
          title: "Error Page 400",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Error Page 401",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Error Page 403",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Error Page 404",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Error Page 500",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Error Page 503",
          type: "extLink",
        },
      ],
    },
    {
      title: "Authentication",
      type: "sub",
      active: false,
      children: [
        { path: "javascript:void(0);", title: "Login Simple", type: "extLink" },
        {
          path: "javascript:void(0);",
          title: "Login BG Image",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Login BG Video",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Simple Register",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Register BG Image",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Register BG Video",
          type: "extLink",
        },
      ],
    },
    {
      title: "Usefull Pages",
      type: "sub",
      active: false,
      children: [
        { path: "javascript:void(0);", title: "Search Pages", type: "extLink" },
        { path: "javascript:void(0);", title: "Unlock User", type: "extLink" },
        {
          path: "javascript:void(0);",
          title: "Forgot Password",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Reset Password",
          type: "extLink",
        },
        { path: "javascript:void(0);", title: "Maintenance", type: "extLink" },
      ],
    },
    {
      title: "Email templates",
      type: "sub",
      active: false,
      children: [
        {
          path: "http://admin.pixelstrap.com/cuba/theme/basic-template.html",
          title: "Basic Email",
          type: "extTabLink",
        },
        {
          path: "http://admin.pixelstrap.com/cuba/theme/email-header.html",
          title: "Basic With Header",
          type: "extTabLink",
        },
        {
          path: "http://admin.pixelstrap.com/cuba/theme/template-email.html",
          title: "Ecomerce Template",
          type: "extTabLink",
        },
        {
          path: "http://admin.pixelstrap.com/cuba/theme/template-email-2.html",
          title: "Email Template 2",
          type: "extTabLink",
        },
        {
          path: "http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html",
          title: "Ecommerce Email",
          type: "extTabLink",
        },
        {
          path: "http://admin.pixelstrap.com/cuba/theme/email-order-success.html",
          title: "Order Success",
          type: "extTabLink",
        },
      ],
    },
    {
      title: "Coming Soon",
      type: "sub",
      active: false,
      children: [
        {
          path: "javascript:void(0);",
          title: "Coming Simple",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Coming BG Image",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Coming BG Video",
          type: "extLink",
        },
      ],
    },
  ];

  LEVELMENUITEMS: Menu[] = [
    {
      path: "javascript:void(0);",
      title: "File Manager",
      icon: "git-pull-request",
      type: "extLink",
    },
    {
      title: "Users",
      icon: "users",
      type: "sub",
      active: false,
      children: [
        {
          path: "javascript:void(0);",
          title: "All Users",
          icon: "users",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "User Profile",
          icon: "users",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Edit Profile",
          icon: "users",
          type: "extLink",
        },
      ],
    },
    {
      path: "javascript:void(0);",
      title: "Bookmarks",
      icon: "heart",
      type: "extLink",
    },
    {
      path: "javascript:void(0);",
      title: "Calender",
      icon: "calendar",
      type: "extLink",
    },
    {
      path: "javascript:void(0);",
      title: "Social App",
      icon: "zap",
      type: "extLink",
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
  levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

  getChildData(): Menu[] {
    this.loginService.createEvent.subscribe((res) => {
      this.MENUITEMS[1].children = this.setEventList();
      this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
    });
    return this.setEventList();
  }

  setEventList(): Menu[] {
    let eventListSubM = [];

    this.localEventData = this.storageService.retrieve("getEventData");
    this.localEventData?.forEach((a) => {
      eventListSubM.push({
        path: "/eventList/event-details/" + a.id,
        title: a.eventName,
        type: "link",
        id: a.id,
      });
    });

    return eventListSubM;
  }

  updateEventData(){
    this.loginService.updateEvent.subscribe((eventDetails=>{
      if (eventDetails) {
        this.MENUITEMS[1].children.forEach((child) => {
          if (child.id === eventDetails.id) {
            child.title = eventDetails.eventName;
          }
        });
      }
    }))
  }
}
