// User Managment Model
export class UsermanagementMainModel {
  
}


// user Permission Model
export class PermissionMainModel {
  permissions: PermissionRecordModel[];
  roles : Role[];
  permissionRoles: PermissionRoleMapping[];
}

export class PermissionRecordModel {
  category:string;
  id: number;
  name: string;
  systemName:string;
}

export class Role {
  id: string;
  name: string;
  isActive: boolean;
}

export class PermissionRoleMapping {
  id: number;
  permissionId: number;
  roleId: string;
}

// user Permission Model

export class usermanagementPaginate{
    pageNumber: number
    pageSize: number
    sortColumn: any
    sortDirection: number
    searchText: any
    id: number
  }
  
