import { LoginModel } from './../login.model';
import { LoginService } from './../login.service';
import { Router } from '@angular/router';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CommonService } from 'src/app/shared/common/commonService/common.service';
import { ResponseModel } from 'src/app/shared/common/commonModel/common.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends CommonService implements OnInit {

  public show: boolean = false;
  public loginForm     : FormGroup;
  public errorMessage  : any;
  public validate      = false;
  //#region

  loginModel = new LoginModel();

  //#endregion

  constructor(
    // public authService: AuthService,
    public router: Router,
    private fb: FormBuilder,
    private loginService: LoginService,
    private toaster: ToastrService,
    public storageservice: StorageService,
    public renderer: Renderer2,) {
    super(renderer);
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }

  // Login With Google
  loginGoogle() {
    // this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
    // this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    // this.authService.signInFacebok();
  }

  // Simple Login
  login() {
    this.validate = !this.validate; 
    if (this.loginForm.valid == true) {
      this.loginService.SignIn(this.loginModel)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res: ResponseModel) => {
          if (res.isSuccess) {
            this.storageservice.store('authorizationDataIdToken', res.response.accessToken);
            this.storageservice.store('userAccountDetails', res.response.user);
            this.loginService.getEventData()
              .subscribe((res: ResponseModel) => {
                if (res.isSuccess) {
                  this.storageservice.store("getEventData",res?.response == ''? []:res?.response)
                  this.router.navigate(['/admin/usermanagement']);
                }
              })
          }
          else{
            this.toaster.error(res.message, null, { timeOut: 700 });
          }
        });
    } else {
      this.toaster.error("Please enter valid mail or password", null, { timeOut: 700 });
    }
  }

  handleError(err: any): any {
    this.toaster.error("Please enter valid mail or password!", null, { timeOut: 700 });
    throw new Error('Method not implemented.');
  }

  openForgetPasswordForm() {
    this.router.navigate(['/forgotpassword']);
  }

  openSignUpForm() {
    this.router.navigate(['/signup']);
  }

}
