import { Injectable, Renderer2 } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { NgxDatatableSSRConfig } from '../commonModel/common.model';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(public renderer: Renderer2) { }

  convertStartAndEndTimeFormat(startTime,endTime){
    var timeStartArr = startTime.split(':');
    var timeEndArr = endTime.split(':');

    var startDate = new Date().setHours(timeStartArr[0], timeStartArr[1], timeStartArr[2])
    var endDate = new Date().setHours(timeEndArr[0], timeEndArr[1], timeEndArr[2])
    
    var startTime1 = moment(startDate).format("h:mma");
    var endTime1 = moment(endDate).format("h:mma");
    return ({startTime:startTime1,endTime:endTime1})
  }

  public ngxDatatableSSRConfig:NgxDatatableSSRConfig;
  onKeydown(event: any, value: any) {
    event.preventDefault();
    const element = this.renderer.selectRootElement(value, true);
    if (event.key === "Enter") {
      element.focus();
    }
  }



  public onPageChange(pageNum: number) {
    this.ngxDatatableSSRConfig.currentPageNumber = pageNum;
    this.ngxDatatableSSRConfig.tableOffset = pageNum - 1;
  }

  public onPageSizeChanged(pageSize: number) {
    this.ngxDatatableSSRConfig.getAll = pageSize == 1;
    this.ngxDatatableSSRConfig.pageSize = pageSize;
    this.onPageChange(1);
  }

  public onSort(pageNum: number) {
    this.onPageChange(1);
  }

  public updateTableData(response: any,rows) {
    this.ngxDatatableSSRConfig.rows = rows;
    this.ngxDatatableSSRConfig.totalRecords = response?.totalFilteredRecords;
    if(response?.totalRecords != undefined){
      this.ngxDatatableSSRConfig.totalRecords = response?.totalRecords;
    }

  }

  public updateTableDataClientSide(response: any[]) {
    this.ngxDatatableSSRConfig.rows = response;
    this.ngxDatatableSSRConfig.totalRecords = this.ngxDatatableSSRConfig.rows?.length;
    //this.setCurrentPage();
  }

  // public updateTableData(response:any){
  //   this.rows = response;
  //   this.totalRecordSize = this.rows.length;
  //   //this.setCurrentPage();
  // }

  // private setCurrentPage(){
  //   var totalPages = this.totalRecordSize/this.pageSize;
  //   var remainder = this.totalRecordSize%this.pageSize;
  //   var currentPageNumber = remainder > 0 ? totalPages : totalPages + 1;
  //   //this.onPageChanged(currentPageNumber);

  //   this.currentPage = currentPageNumber;
  //   this.tableOffset = currentPageNumber-1;
  // }
}



