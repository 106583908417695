<div class="container-fluid p-4">
<div class="row">
    <div class="col-12">
        <div>
            <form action="" class="theme-form" ngNativeValidate [formGroup]="inviteParticipantForm">
                <span  class="color-orange f-26">Invite Participant
                </span>
                <div class="form-group row gx-5 mb-0">
                    <div class="col-md-4 col-sm-6">
                        <label class="col-form-label color-black">First Name</label>
                        <input
                            class="form-control" 
                            autocomplete="disabled" 
                            type="text" 
                            required="true"
                            title="" 
                            (keydown.enter)="onKeydown($event, '#lastName')" 
                            placeholder="John" 
                            formControlName="firstName"
                            name="inviteParticipantModel.fitstName"
                            onlyAlphabets
                            [(ngModel)]="inviteParticipantModel.fitstName"
                        >
                        <!-- <div *ngIf="inviteParticipantForm.controls.firstName.touched && inviteParticipantForm.controls.firstName.errors?.required"
                          class="text text-danger mt-1 f-12">
                          First Name is required
                        </div> -->
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <label class="col-form-label color-black">Last Name</label>
                        <input  
                            class="form-control" 
                            autocomplete="disabled" 
                            type="text" 
                            required="true"
                            title=""  
                            placeholder="Deo" 
                            id="lastName"
                            (keydown.enter)="onKeydown($event, '#Email')"
                            formControlName="lastName"
                            name="inviteParticipantModel.lastName"
                            [(ngModel)]="inviteParticipantModel.lastName"
                            onlyAlphabets
                        >
                        <!-- <div *ngIf="inviteParticipantForm.controls.lastName.touched && inviteParticipantForm.controls.lastName.errors?.required"
                            class="text text-danger mt-1 f-12">
                            Last Name is required
                        </div> -->
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <label class="col-form-label color-black text-nowrap">Email Address</label>
                        <input 
                            class="form-control" 
                            autocomplete="disabled" 
                            type="email" 
                            required="true"
                            title=""  
                            id="Email"
                            (keydown.enter)="onKeydown($event, '#send')"
                            placeholder="John@mail.com" 
                            formControlName="Email"
                            name="inviteParticipantModel.Email"
                            [(ngModel)]="inviteParticipantModel.Email"
                        >
                        <!-- <div *ngIf="inviteParticipantForm.controls.Email.touched && inviteParticipantForm.controls.Email.errors?.required"
                            class="text text-danger mt-1 f-12">
                            Email Name is required
                        </div> -->
                      </div>
                  </div>
                  <div class="form-group row mt-4 mb-0">
                    <div class="col-4"></div>
                    <div class="col-4"></div>
                    <div class="col-md-4 col-sm-6  d-flex justify-content-end">
                      <button (click)="submit()"  class="btn-signIn background-orange mx-2 w-100px p-1 border-0"
                      id="send"
                        type="submit"><span class="f-16 font-Rubik-bold">Send</span>
                      </button>
                      <button class="btn-signIn background-grey ml-2 w-100px p-1 border-0"
                        type="button" (click)="closePopUp()"><span class="f-16 font-Rubik-bold">Cancel</span>
                      </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</div>