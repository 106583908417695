<div class="container-fluid p-4">
    <div class="row">
      <div class="col-12 d-flex flex-column align-items-center gap-2">     
        <div class="color-orange f-26 text-left px-4">Cannot Edit Event</div>
            <div class="my-5">
                <span class="font-Rubik-regular color-gray f-18">{{eventMessage}}</span>
            </div>
            <div class="mb-5"> 
                <button id="close" class="btn-signIn background-grey w-100px px-1 py-2 border-0"
                        type="button" (click)="activeModal.close()"><span class="f-16">Close</span>
                </button>
            </div>
      </div>
    </div>
  </div>