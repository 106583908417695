import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/common/commonService/common.service';
import {InviteParticipantModel} from '../invite-participant.model'

@Component({
  selector: 'app-invite-participant',
  templateUrl: './invite-participant.component.html',
  styleUrls: ['./invite-participant.component.scss']
})
export class InviteParticipantComponent extends CommonService implements OnInit {

  public inviteParticipantForm  : FormGroup;
  public errorMessage           : any;
  public inviteParticipantModel = new InviteParticipantModel();
  public validate               = false;

  constructor(
    public renderer   : Renderer2, 
    public router     : Router,
    public activeModal: NgbActiveModal,
    private fb        : FormBuilder,
    )
    { 
      super(renderer);
      this.inviteParticipantForm = this.fb.group({
        firstName: ['', [Validators.required]],
        lastName:  ['', [Validators.required]],
        Email:     ['', [Validators.required, Validators.email]],
      });
     }

  ngOnInit(): void {
  }

  closePopUp(){
    this.activeModal.dismiss();
  }

  public submit() {
    this.validate = !this.validate;
  }

}