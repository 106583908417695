export class Login {}
export class LoginModel {
  email: string;
  password: string;
}
export class ForgotPasswordModel {
  email: string;
}
export class ResetPasswordModel {
  email: string;
  password: string;
  confirmPassword?: string;
  token: string;
}
export class VerifyOtpModel {
  otp: string;
  email: string;
}
