import { PermissionMainModel, PermissionRecordModel } from './../usermanagement.model';
import { ToastrService } from 'ngx-toastr';
import { UsermanagementService } from './../usermanagement.service';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgxDatatableSSRConfig, PaginationBaseModel, ResponseModel } from 'src/app/shared/common/commonModel/common.model';
import { CommonService } from 'src/app/shared/common/commonService/common.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent extends CommonService implements OnInit {
  paginationBaseModel = new PaginationBaseModel();
  permissionMainModel = new PermissionMainModel();
  public company      = [];
  loadingIndicator    : boolean = true;
  reorderable         : boolean = true;
  rows                : PermissionRecordModel[] = [];
  isLoading           : boolean = false;
  emptyMessage: string;

  constructor(public renderer: Renderer2,
              public usermanagementService: UsermanagementService,
              public toaster: ToastrService,) 
              {
                 super(renderer);
              }

  ngOnInit(): void {
    this.initDatatable();
    this.loadListData();
  }

  initDatatable(): void {
    this.paginationBaseModel = new PaginationBaseModel();
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.pageSize = 10;
    this.paginationBaseModel = {
      sortColumn: null,
      sortDirection: 0,
      pageNumber: this.ngxDatatableSSRConfig.currentPageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText
    };
  }

  loadListData() {
    this.usermanagementService.getAllPermission(this.paginationBaseModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess === true) {
          this.updateTableData(res.response, res.response.permissions);
          this.permissionMainModel = res.response;
          this.rows = this.permissionMainModel?.permissions;
          this.emptyMessage = res?.message == ''? 'No records found!':res?.message;
        }
      });
  }

  onPageChanged(pageNum: number) {
    this.ngxDatatableSSRConfig.rows = [];
    this.onPageChange(pageNum);
    this.paginationBaseModel.pageNumber = this.ngxDatatableSSRConfig.currentPageNumber;
    this.loadListData();
  }

  sortTableData() {
    this.paginationBaseModel.searchText = this.ngxDatatableSSRConfig.searchText;
    this.loadListData()
  }

  getIsSelected(permissionId, roleId): boolean {
    return (this.permissionMainModel?.permissionRoles.find(a => a.permissionId == permissionId && a.roleId == roleId) == undefined) ? false : true;
  }

  getRoleIsAdmin(userRole): boolean {
    return (userRole == 'Super - Admin');
  }

  savePermission(permission: any, role: any, event: any) {
    console.log(permission.id);

    var isChecked = event.target.checked;
    Swal.fire({
      title: 'Do you want to save the changes?',
      //showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        // if ('AdminAccess' === permission.systemName) {
        //   if (this.isLoading) {
        //     return;
        //   }
        // }
        this.isLoading = true;
        const data = { permissionId: permission.id, roleTypeId: role.id, isChecked: isChecked }
        console.log(data);

        this.usermanagementService.savePermission(data)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res: ResponseModel) => {
            if (res.isSuccess == true) {
              // this.isLoading = false;
              // if ('AdminAccess' === permission.systemName) {
              //   if (isChecked) {
              //     this.loadListData();
              //   }
              // }
            }
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        event.target.checked = !isChecked;
      } else if(result.dismiss == 'backdrop'){
        event.target.checked = !isChecked;
      }
    });
  }

  clearSearchText() {
    this.ngxDatatableSSRConfig.searchText = "";
    this.sortTableData();
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);
    return throwError(error);
  }
}
