<!-- <i [attr.data-feather]="icon"></i> -->
<img *ngIf="icon == 'grid'" src="../../../../assets/images/Asset 10-crop.png" width="35px" height="57px" alt="">

<i *ngIf="icon == 'middle'" class="fa fa-angle-right color-white"></i>

<div class="image-width" *ngIf="icon=='admin'">
    <svg  xmlns="http://www.w3.org/2000/svg" width="37.446" height="22.728" viewBox="0 0 37.446 42.728" str>
        <g id="user_5_" data-name="user (5)" transform="translate(-7.023 -3.5)">
          <path id="Path_682" data-name="Path 682" d="M43.511,8.767,26.069,3.547a1.125,1.125,0,0,0-.645,0L7.98,8.767a1.125,1.125,0,0,0-.8.99c-1.047,13.519,3.129,25.919,10.9,32.365a21.078,21.078,0,0,0,7.662,4.105,21.034,21.034,0,0,0,7.647-4.094c7.781-6.451,11.964-18.858,10.917-32.377a1.125,1.125,0,0,0-.8-.99ZM31.962,40.4a20.707,20.707,0,0,1-6.215,3.513,20.764,20.764,0,0,1-6.23-3.524C12.444,34.524,8.588,23.2,9.37,10.7L25.746,5.8l16.375,4.9c.782,12.5-3.08,23.831-10.16,29.7Z" transform="translate(0 0)" fill="#1b1b1b"/>
          <path id="Path_683" data-name="Path 683" d="M33,28.665a5.338,5.338,0,1,0-7.441,0,7.686,7.686,0,0,0-4.432,6.951,1.124,1.124,0,1,0,2.249,0,5.572,5.572,0,0,1,6.372-5.435,5.44,5.44,0,0,1,5.435,5.435,1.124,1.124,0,0,0,2.249,0A7.686,7.686,0,0,0,33,28.665Zm-3.721-6.916a3.092,3.092,0,1,1-3.092,3.092,3.092,3.092,0,0,1,3.092-3.092Z" transform="translate(-3.531 -4.006)" fill="#1b1b1b"/>
        </g>
    </svg>
</div>
<div class="image-width" *ngIf="icon=='eventList'">
    <svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="42" height="22" viewBox="0 0 42 42">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_1534" data-name="Rectangle 1534" width="42" height="42" transform="translate(0.483 0.293)" fill="#1b1b1b" stroke="#707070" stroke-width="1"/>
          </clipPath>
        </defs>
        <g id="Group_193" data-name="Group 193" transform="translate(-0.483 -0.293)">
          <g id="Mask_Group_10" data-name="Mask Group 10" transform="translate(0 0)" clip-path="url(#clip-path)">
            <g id="placeholder" transform="translate(5.209 0)">
              <path id="Path_502" data-name="Path 502" d="M33.39,8.1a15.813,15.813,0,0,0-27.769.292,15.68,15.68,0,0,0,.6,16.023l10.244,16.04a3.707,3.707,0,0,0,6.248,0l10.244-16.04A15.749,15.749,0,0,0,33.39,8.1ZM30.872,23.085,20.627,39.126a1.235,1.235,0,0,1-2.084,0L8.3,23.085A13.238,13.238,0,0,1,7.8,9.558a13.342,13.342,0,0,1,23.435-.248A13.212,13.212,0,0,1,30.872,23.085Z" transform="translate(-3.706 0)" fill="#1b1b1b"/>
              <path id="Path_503" data-name="Path 503" d="M18.042,3.809A10.532,10.532,0,1,0,28.574,14.341,10.544,10.544,0,0,0,18.042,3.809Zm0,18.593A8.061,8.061,0,1,1,26.1,14.341,8.07,8.07,0,0,1,18.042,22.4Z" transform="translate(-2.163 1.545)" fill="#1b1b1b"/>
            </g>
          </g>
        </g>
    </svg>
</div>

  