<div class="container-fluid p-4">
    <div class="row">
        <div class="col-12">
            <div class="login-main">
                <form class="theme-form" ngNativeValidate #createEventForm="ngForm"> 
                    <div class="row">
                        <div class="col-lg-3 col-0"></div>
                        <div class="col-lg-4 col-12 color-orange f-26 text-left px-4">{{editEventData?"Edit Event":"Create Event"}}</div>
                        <div class="col-lg-4 col-0"></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <label class="d-block" for="imginput">
                            <div 
                                class="Imgbox cursor-pointer px-0 color-orange my-2 mx-1 d-flex justify-content-center align-items-center"
                                >
                                <img *ngIf="showImg"
                                class="fixedimg"
                                [src]="showImg" 
                                alt="Cover Image" 
                                name="eventCoverPhotoId"
                                > 
                                <div *ngIf="showImg?false:true" class="color-gray opacity-50">Cover Image</div> 
                            </div>
                            </label>
                            <div class="text-center">
                                <label for="imginput" class="btn btn-block btn-signIn color-orange py-2 px-2 mt-2 f-12 font-rubik-medium">{{editEventData?"Edit Cover Image":"Upload Cover Image"}}</label>
                                <input type="file" id="imginput" name="imginput" class="d-none" [required]="showImg?false:true" [ngModel]="createEventModel?.eventCoverPhotoId" (ngModelChange)="createEventModel.eventCoverPhotoId = $event" (change)="fileChangeEvent($event)" (click)="changeFile($event)"/> 
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="row d-flex justify-content-around">
                                <div class="form-group row mb-1 px-0">
                                    <div class="col-lg-6 px-4">
                                        <label class="col-form-label color-black">Event Name</label>
                                        <input 
                                            class="form-control " 
                                            autocomplete="nope" 
                                            type="text" 
                                            required 
                                            title=""
                                            (keydown.enter)="onKeydown($event, '#eventDate')"
                                            id="eventName"
                                            [ngModel]="createEventModel?.eventName" (ngModelChange)="createEventModel.eventName = $event"
                                            name="eventName"
                                        >
                                        <!-- <div *ngIf="createEventForm.controls.eventName.touched && createEventForm.controls.eventName.errors?.required"
                                            class="text text-danger mt-1 f-12">
                                            Event Name is required
                                        </div>  -->
                                    </div>
                                    <div class="col-lg-6 px-4 px-lg-3">
                                        <label class="col-form-label color-black">Date</label>
                                        <input 
                                            type="date"
                                            class="form-control py-1" 
                                            autocomplete="nope" 
                                            id="eventDate"
                                            title=""
                                            (keydown.enter)="onKeydown($event, '#eventMode')"
                                            onfocus="this.showPicker()"
                                            required="false"
                                            min="{{todayDate | date:'yyyy-MM-dd'}}"
                                            placeholder=""
                                            [(ngModel)]="eventDate"
                                            name="eventDate"  
                                        > 
                                        <!-- <div *ngIf="createEventForm.controls.date.touched && createEventForm.controls.date.errors?.required"
                                            class="text text-danger mt-1 f-12">
                                            Event Name is required
                                        </div> -->
                                    </div>
                                </div>
                                <div class="form-group row mb-1 px-0">
                                    <div class="col-lg-6 px-4">
                                        <label for="eventType" class="col-form-label color-black">Event Mode</label>
                                        <select
                                            class="p-2" 
                                            required="true"   
                                            id="eventMode"
                                            title=""
                                            (keydown.enter)="onKeydown($event, '#startTime')"
                                            [ngModel]="createEventModel?.eventModeId" (ngModelChange)="createEventModel.eventModeId = $event"
                                            name="eventModeId"
                                            [disabled]="editEventData"
                                        >
                                            <option *ngFor="let item of eventModelist | populateEnum" [value]="item.key" >{{item.value}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6 px-4 px-lg-3">
                                        <div class="d-flex col-12">
                                            <div class="col-6 p-r-5">
                                                <label for="startTime" class="col-form-label color-black">Start Time</label>
                                                <input 
                                                    class="form-control digits" 
                                                    autocomplete="nope" 
                                                    type="time" 
                                                    id="startTime"
                                                    required="false"
                                                    title=""
                                                    (keydown.enter)="onKeydown($event, '#endTime')"
                                                    onfocus="this.showPicker()"
                                                    [ngModel]="createEventModel?.eventStartDateTime" (ngModelChange)="createEventModel.eventStartDateTime = $event"
                                                    name="startTime"
                                                >
                                                <!-- <div *ngIf="createEventForm.controls.StartTime.touched && createEventForm.controls.StartTime.errors?.required"
                                                    class="text text-danger mt-1 f-12">
                                                    Time required
                                                </div> -->
                                            </div>
                                             
                                            <div class="col-6 p-l-5">
                                                <label for="endTime" class="col-form-label color-black">End Time</label>
                                                <input 
                                                    class="form-control digits" 
                                                    autocomplete="nope" 
                                                    type="time" 
                                                    id="endTime"
                                                    required="false"
                                                    title=""
                                                    (keydown.enter)="InputDisabled() == true? onKeydown($event, '#description') : onKeydown($event, '#venueName')"
                                                    onfocus="this.showPicker()"
                                                    [ngModel]="createEventModel?.eventEndDateTime" (ngModelChange)="createEventModel.eventEndDateTime = $event"
                                                    name="endTime"
                                                >
                                                <!-- <div *ngIf="createEventForm.controls.EndTime.touched && createEventForm.controls.EndTime.errors?.required"
                                                    class="text text-danger mt-1 f-12">
                                                    Time required
                                            </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row mb-1 px-0">
                                    <div class="col-lg-6 px-4">
                                        <label class="col-form-label color-black">Venue Name</label>
                                        <input 
                                            class="form-control " 
                                            autocomplete="nope" 
                                            type="text"
                                            id="venueName"
                                            title="" 
                                            (keydown.enter)="onKeydown($event, '#city')"
                                            [required]="InputDisabled() != true? true : null" 
                                            [ngModel]="createEventModel?.venueName" (ngModelChange)="createEventModel.venueName = $event"
                                            name="venueName"
                                            OnlyAlphabetsSpaceAllowed
                                            [ngClass]="{'background-faintGrey color-transparent': InputDisabled() == true }"
                                            [attr.disabled]="InputDisabled() == true? true : null"
                                        >
                                    </div>
                                    <div class="col-lg-6 px-4 px-lg-3">
                                        <label class="col-form-label color-black">City</label>
                                        <input 
                                            class="form-control" 
                                            autocomplete="nope" 
                                            type="text" 
                                            [required]="InputDisabled() != true? true : null"
                                            id="city"
                                            title="" 
                                            (keydown.enter)="onKeydown($event, '#eventAddress1')"
                                            [ngModel]="createEventModel?.city" (ngModelChange)="createEventModel.city = $event"
                                            name="city"
                                            onlyAlphabets
                                            [ngClass]="{'background-faintGrey color-transparent': InputDisabled() == true }"
                                            [attr.disabled]="InputDisabled() == true? true : null"
                                        >
                                        <!-- <div *ngIf="createEventForm.controls.City.touched && createEventForm.controls.City.errors?.required"
                                            class="text text-danger mt-1 f-12">
                                            City is required
                                        </div> -->
                                    </div>
                                </div>
                                <div class="form-group row mb-1 px-0">
                                    <div class="col-lg-6 px-4">
                                        <label class="col-form-label color-black">Event Address 1</label>
                                        <input 
                                            class="form-control" 
                                            autocomplete="nope" 
                                            type="text" 
                                            [required]="InputDisabled() != true? true : null"
                                            id="eventAddress1"
                                            title=""
                                            (keydown.enter)="onKeydown($event, '#zipCode')" 
                                            [ngModel]="createEventModel?.eventAddress1" (ngModelChange)="createEventModel.eventAddress1 = $event"
                                            name="eventAddress1"
                                            [ngClass]="{'background-faintGrey color-transparent': InputDisabled() == true }"
                                            [attr.disabled]="InputDisabled() == true? true : null"
                                        >
                                        <!-- <div *ngIf="createEventForm.controls.eventAddress1.touched && createEventForm.controls.eventAddress1.errors?.required"
                                            class="text text-danger mt-1 f-12">
                                            Event Address 1 is required
                                        </div> -->
                                    </div>
                                    <div class="col-lg-6 px-4 px-lg-3">
                                        <label class="col-form-label color-black">Zipcode</label>
                                        <input 
                                           class="form-control" 
                                           autocomplete="nope" 
                                           type="text" 
                                           [required]="InputDisabled() != true? true : null"
                                           id="zipCode"
                                           title=""
                                           (keydown.enter)="onKeydown($event, '#eventAddress2')"
                                           [ngModel]="createEventModel?.zipCode" (ngModelChange)="createEventModel.zipCode = $event"
                                           name="zipCode"
                                           onlyNumbers
                                           [ngClass]="{'background-faintGrey color-transparent': InputDisabled() == true }"
                                           [attr.disabled]="InputDisabled() == true? true : null"
                                        >
                                        <!-- <div *ngIf="createEventForm.controls.Zipcode.touched && createEventForm.controls.Zipcode.errors?.required"
                                            class="text text-danger mt-1 f-12">
                                            Zipcode is required
                                        </div> -->
                                    </div>
                                </div>
                                <div class="form-group row mb-1 px-0">
                                    <div class="col-lg-6 px-4">
                                        <label class="col-form-label color-black">Event Address 2</label>
                                        <input 
                                            class="form-control" 
                                            autocomplete="nope" 
                                            type="text"
                                            title="" 
                                            [required]="InputDisabled() != true? false : false"
                                            id="eventAddress2" 
                                            (keydown.enter)="onKeydown($event, '#state')"
                                            [ngModel]="createEventModel?.eventAddress2" (ngModelChange)="createEventModel.eventAddress2 = $event"
                                            name="eventAddress2"
                                            [ngClass]="{'background-faintGrey color-transparent': InputDisabled() == true }"
                                            [attr.disabled]="InputDisabled() == true? true : null"
                                        >
                                        <!-- <div *ngIf="createEventForm.controls.eventAddress2.touched && createEventForm.controls.eventAddress2.errors?.required"
                                            class="text text-danger mt-1 f-12">
                                            Event Address 2 is required
                                        </div> -->
                                    </div>
                                    <div class="col-lg-6 px-4 px-lg-3">
                                        <label class="col-form-label color-black">State</label>
                                        <input 
                                            class="form-control" 
                                            autocomplete="nope" 
                                            type="text" 
                                            [required]="InputDisabled() != true? true : null"
                                            id="state"
                                            title="" 
                                            (keydown.enter)="onKeydown($event, '#description')"
                                            [ngModel]="createEventModel?.state" (ngModelChange)="createEventModel.state = $event"
                                            name="state"
                                            OnlyAlphabetsSpaceAllowed
                                            [ngClass]="{'background-faintGrey color-transparent': InputDisabled() == true }"
                                            [attr.disabled]="InputDisabled() == true? true : null"
                                        >
                                        <!-- <div *ngIf="createEventForm.controls.State.touched && createEventForm.controls.State.errors?.required"
                                            class="text text-danger mt-1 f-12">
                                            State is required
                                        </div> -->
                                    </div>
                                </div>

                                <div class="form-group row mb-1 px-0">
                                    <div class="col-lg-6 px-4">
                                        <label for="description" class="col-form-label color-black">Description</label>
                                        <textarea
                                            style="line-height: 1.56;"
                                            class="form-control f-12 py-2" 
                                            autocomplete="nope"
                                            rows="4"
                                            required="true"
                                            id="description"
                                            title="" 
                                            (keydown.enter)="onKeydown($event, '#eventType')"
                                            [ngModel]="createEventModel?.description" (ngModelChange)="createEventModel.description = $event"
                                            name="description"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="col-lg-6 px-4 px-lg-3 font-rubik-medium">
                                        <div class="row dist-height">
                                            <div class="">
                                                <label for="eventType" class="col-form-label color-black">Event Type</label>
                                                <select
                                                    class="p-2" 
                                                    required="true"   
                                                    id="eventType"
                                                    title=""
                                                    (keydown.enter)="onKeydown($event, '#swimmingDistance')"
                                                    [ngModel]="createEventModel?.eventTypeId" (ngModelChange)="createEventModel.eventTypeId = $event"
                                                    name="eventTypeId"
                                                >
                                                    <option *ngFor="let item of eventTypelist | populateEnum" [value]="item.key" >{{item.value}}</option>
                                                </select>
                                                <!-- <div *ngIf="createEventForm.controls.EventType.touched && createEventForm.controls.EventType.errors?.required"
                                                    class="text text-danger mt-1 f-12">
                                                    required
                                                </div> -->
                                            </div>
                                            <div class="col-lg-4 col-md-3 col-4 d-flex align-items-center mb-lg-0 mb-md-3 mb-3">
                                                <label class="col-form-label color-black">Distance:</label>
                                            </div>
                                            <div class="col-lg-8 col-md-7 col-7 mb-0">
                                                <div class="col-12 d-flex justify-content-start">
                                                    <div class="col-4 p-r-5 p-l-5" *ngIf="createEventModel?.eventTypeId != 2 && createEventModel?.eventTypeId != 3">
                                                        <label class="col-form-label color-black f-8 mt-lg-2 mt-1">Swimming</label>
                                                        <input 
                                                            class="form-control p-2" 
                                                            autocomplete="nope"
                                                            required
                                                            title="" 
                                                            type="text" 
                                                            id="swimmingDistance"
                                                            (keydown.enter)="onKeydown($event, '#cyclingDistance')"
                                                            [ngModel]="createEventModel?.swimmingDistance" (ngModelChange)="createEventModel.swimmingDistance = $event"
                                                            name="swimmingDistance"
                                                            [disabled]="createEventModel?.eventTypeId == 2"
                                                            appNumberWithTwoDecimalPlaces
                                                        >
                                                        <!-- <div *ngIf="createEventForm.controls.Swimming.touched && createEventForm.controls.Swimming.errors?.required"
                                                            class="text text-danger mt-1 f-12">
                                                            required
                                                        </div> -->
                                                    </div>
                                                    <div class="col-4 p-r-5 p-l-5" *ngIf="createEventModel?.eventTypeId != 2 && createEventModel?.eventTypeId != 3">
                                                        <label class="col-form-label color-black f-8 mt-lg-2 mt-1">Cycling</label>
                                                        <input 
                                                            class="form-control p-2" 
                                                            autocomplete="nope" 
                                                            type="text"
                                                            required
                                                            title="" 
                                                            id="cyclingDistance"
                                                            (keydown.enter)="onKeydown($event, '#runningDistance')"
                                                            [ngModel]="createEventModel?.cyclingDistance" (ngModelChange)="createEventModel.cyclingDistance = $event"
                                                            name="cyclingDistance"
                                                            [disabled]="createEventModel?.eventTypeId == 2"
                                                            appNumberWithTwoDecimalPlaces
                                                        >
                                                        <!-- <div *ngIf="createEventForm.controls.Cycling.touched && createEventForm.controls.Cycling.errors?.required"
                                                            class="text text-danger mt-1 f-12">
                                                            required
                                                        </div> -->
                                                    </div>
                                                    <div class="col-4 p-r-5 p-l-5">
                                                        <label class="col-form-label color-black f-8 mt-lg-2 mt-1">Running</label>
                                                        <input 
                                                            class="form-control p-2" 
                                                            autocomplete="nope" 
                                                            type="text"
                                                            required
                                                            title="" 
                                                            id="runningDistance"
                                                            (keydown.enter)="onKeydown($event, '#save')"
                                                            [ngModel]="createEventModel?.runningDistance" (ngModelChange)="createEventModel.runningDistance = $event"
                                                            name="runningDistance"
                                                            appNumberWithTwoDecimalPlaces
                                                        >
                                                        <!-- <div *ngIf="createEventForm.controls.Running.touched && createEventForm.controls.Running.errors?.required"
                                                            class="text text-danger mt-1 f-12">
                                                            required
                                                        </div> -->
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-2"></div>
                                    <div class="col-lg-3 col-md-2 col-1"></div>
                                    <div class="col-lg-6 col-md-7 col-9">
                                        <div class="form-group row mb-0 mt-3">
                                            <div class="px-3 d-flex justify-content-between">
                                                <button
                                                    [ngClass]="" 
                                                    class="btn-signIn background-orange w-100px p-1 border-0"
                                                    type="submit"
                                                    id="save"
                                                    (click)="editEventData?editEvent():createEventSaveData()"
                                                    >
                                                    <span class="f-16 font-Rubik-bold">
                                                        Save
                                                    </span>
                                                </button>
                                                <button class="btn-signIn background-grey w-100px p-1 border-0"
                                                    type="button" (click)="closePopUp()"><span class="f-16 font-Rubik-bold">Cancel</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group row mb-0 mt-3">
                        <div class="col-lg-8 col-md-5 col-3"></div>
                        <div class="col-lg-4 col-md-7 col-9 px-3 d-flex justify-content-between">
                          <button 
                            class="btn-signIn background-orange w-100px px-1 py-2 border-0"
                            type="button"
                            (click) ="createEventSaveData()"
                            >
                            <span class="f-16 font-Rubik-bold">
                                Save
                            </span>
                          </button>
                          <button class="btn-signIn background-grey w-100px px-1 py-2 border-0"
                            type="button" (click)="closePopUp()"><span class="f-16 font-Rubik-bold">Cancel</span>
                          </button>
                        </div>
                    </div> -->
                </form>
            </div>
        </div>
    </div>
</div>

